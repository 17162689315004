import React, { useState, useEffect } from 'react';

import 'moment/locale/es';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import DeleteIcon from '@material-ui/icons/Delete';
import Validations from '../../../helpers/Validations';
import TextField from '@material-ui/core/TextField';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import './SectionForm.scss';
import { useTheme } from "@material-ui/core/styles";
import 'survey-core/defaultV2.css';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowCircleDownIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowCircleUpIcon from '@material-ui/icons/ArrowUpwardOutlined';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { ISection } from '../../../interfaces/ISection';
import { sortByKeyDesc } from '../../../helpers/sharedFunctions';
import { getActiveQuestions } from '../../../services/QuestionService';
import { associateQuestion, createSection, deleteSection, disassociateQuestion, getSectionQuestions, updateSection, updateassociatedQuestion } from '../../../services/SectionService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ResponsiveBasicModal from '../../ResponsiveBasicModal';
import QuestionForm from '../QuestionForm';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import ModalOffersAction from '../../LaboralOffers/ModalOffersAction';
import { IQuestion } from '../../../interfaces/IQuestion';
import { IQuestionSection, RedirectionFlow } from '../../../interfaces/IQuestionSection';
import { TYPES } from '../SurveyComponent/SurveyComponent';
const initialFormValues = {
  title: '',
  description: '',
};

const formValidations = {
  title: {
    error: '',
    validate: 'textNumber',
    maxLength: 500,
    minLength: 1,
    required: true
  },
  description: {
    error: '',
    validate: 'textNumber',
    maxLength: 500,
    minLength: 1,
    required: false
  }
};

enum OrderDirection {
  UP,
  DOWN
}


interface SectionFormProps {
  surveyInfo: IGetSurveys;
}

const SectionForm: React.FC<SectionFormProps> = ({ surveyInfo }) => {
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState<ISection[]>(surveyInfo.Sections!);

  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [successMessage, setSuccessMessage] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [questionFiltered, setQuestionFiltered] = useState<Array<IQuestion>>([]);
  const [openModalQuestion, setOpenModalQuestion] = React.useState(false);
  const [disabledAssociate, setDisabledAssociate] = React.useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState<IQuestion>(new IQuestion({}));
  const [selectedSection, setSelectedSection] = useState<ISection>();
  const [displayedSection, setDisplayedSection] = useState(0);
  const [currentQuestions, setCurrentQuestions] = useState<IQuestionSection[]>([]);
  const [expandedFlows, setExpandedFlows] = useState<string[]>([]);

  const getListAvailableQuestions = async () => {
    if (!selectedSection && surveyInfo.Sections && surveyInfo.Sections?.length > 0) {
      loadCurrentQuestions(surveyInfo.Sections[0].id);
      setSelectedSection(surveyInfo.Sections[0]);
    }
    const getInfo: Response = await trackPromise(getActiveQuestions(surveyInfo.id)) as Response;
    if (getInfo.status === 200) {
      let resultList = (await getInfo.json()).message;
      resultList = sortByKeyDesc(resultList, 'title');
      setQuestionFiltered(resultList);
    }
  }

  const expandReduce = (flow: string) =>{

    if(expandedFlows.includes(flow)){
      setExpandedFlows(expandedFlows.filter(f=>{return f!==flow}))
    }else{
      setExpandedFlows([...expandedFlows, flow])
    }
    
  }

  const loadCurrentQuestions = async (selectedSection: number) => {

    const resultSection: Response = await trackPromise(getSectionQuestions(selectedSection)) as Response;
    if (resultSection.status !== 200) {
      setErrorMessage(
        'Hubo un error al consultar los datos de la encuesta, intente de nuevo mas tarde'
      );
      setOpenModalError(true);
      return;
    } else {

      const questions: IQuestionSection[] = ((await resultSection.json()).questions);

      const questionsWithFlows = questions.map(question =>{
        if(questionHasFlow(question.Questions)){
            const flows = question.Questions.jsonText.choices.map((choice:string) =>{
              question.redirect = (question.redirect && Array.isArray(question.redirect))?question.redirect:[];
              return {"answer": choice, "tagetSectionId": (question.redirect.find(flow=>flow.answer===choice) || {"answer": "Sin Flujo", "tagetSectionId": -1}).tagetSectionId  }
            });
            question.redirect = flows;
            return question;
        }
        return question;
      });
      setCurrentQuestions(questionsWithFlows);
    }
  }

  useEffect(() => {
    getListAvailableQuestions();
  }, [])


  const handleChangeSection = (index: number, section: ISection) => {
    setDisplayedSection(index);
    setSelectedSection(section);
    loadCurrentQuestions(section.id);
  }

  const handleCloseModalSuccess = () => {
    setOpenModalSuccess(false);
  };


  const handleCloseModalError = () => {
    setOpenModalError(false);
  };


  const handleChange = (index: number) => (e: any) => {
    const newFormValues = formValues.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setFormValues(newFormValues);

    const error = Validations(e.target.name, e.target.value, formValidations) || '';
    setFormErrors((prev) => ({
      ...prev,
      [e.target.name]: error
    }));

  };


  const isValid = (seccion: ISection) => {
    return seccion.title.length > 0 && !formErrors.title && !formErrors.description;
  }

  const handleChangeQuestion = (event: any, value: any) => {
    if (value) {
      const question: IQuestion = value;
      setSelectedQuestion(question);
      setDisabledAssociate(false);
    } else {
      setDisabledAssociate(true);
      setSelectedQuestion(new IQuestion({}));
    }

  }

  const handleChangeRequired = (questionSection: IQuestionSection) => {
    setCurrentQuestions(currentQuestions.map(question => {
      if (question.id === questionSection.id) {
        question.required = !question.required;
        updateAssociated(question);
      }
      return question;
    }));

  }

  const handleChangeOrder = (questionSection: IQuestionSection, direction: OrderDirection) => {
    var questionIndex = currentQuestions.map(q => { return q.id; }).indexOf(questionSection.id);
    setCurrentQuestions(currentQuestions.map((question, index) => {
      if (index === questionIndex) {
        question.order = direction === OrderDirection.UP ? question.order + 1 : question.order - 1;
        updateAssociated(question);
      }
      else if (index === questionIndex + 1 && direction === OrderDirection.UP) {
        question.order = question.order - 1;
        updateAssociated(question);
      } else if (index === questionIndex - 1 && direction === OrderDirection.DOWN) {
        question.order = question.order + 1;
        updateAssociated(question);
      }
      return question;
    }).sort((a, b) => (a.order < b.order ? -1 : 1)));

  }

  const handleClickOpen = (section: ISection) => {
    setSelectedSection(section);
    setOpenModalQuestion(true);

  };

  const handleCloseEdit = () => {
    setOpenModalQuestion(false);
  };

  const updateQuestions = async (question: IQuestion) => {
    console.log(question);
    setSelectedQuestion(question);
    if (selectedSection) {
      associate(selectedSection, question);
    }

  };

  const closeModalConfirm = () => {
    setOpenModalConfirm(false);
  }


  const confirmDeleteSection = async (section: ISection) => {
    setSelectedSection(section);
    setOpenModalConfirm(true);
  }

  const removeSection = async () => {

    let resultUpdate: Response;
    const sectionToSend = {
      "id": selectedSection!.id,
    }
    resultUpdate = await trackPromise(deleteSection(sectionToSend)) as Response;

    if (resultUpdate.status === 200) {

      const newSectionList = formValues.filter((section) => {
        return section.id !== sectionToSend.id;
      });

      setFormValues(newSectionList);
      setSuccessMessage(`La sección se ha eliminado de manera exitosa`);
      setOpenModalConfirm(false);
      setOpenModalSuccess(true);
      setDisplayedSection(0);
    } else {
      setErrorMessage(
        'Hubo un error al consultar eliminar la sección, intente de nuevo mas tarde'
      );
      setOpenModalConfirm(false);
      setOpenModalError(true);
    }
  };

  const update = async (section?: ISection) => {
    let sectionToSend;
    let resultUpdate: Response;

    if (section) {
      sectionToSend = {
        "id": section!.id,
        "title": section!.title,
        "description": section!.description,
        "surveyId": surveyInfo.id,
        "order": section!.order
      }
      resultUpdate = await trackPromise(updateSection(sectionToSend)) as Response;

    } else {
      sectionToSend = {
        "title": "Sección " + ((formValues || []).length + 1),
        "description": "Descripción",
        "surveyId": surveyInfo.id,
      }
      resultUpdate = await trackPromise(createSection(sectionToSend)) as Response;
      const section: ISection = new ISection((await resultUpdate.json()).section);

      setFormValues((sectionList: ISection[]) => [...sectionList, section]);

      handleChangeSection(formValues.length, section);
    }

    if (resultUpdate.status === 200) {
      setSuccessMessage(`La sección se ha ${section ? 'actualizado' : 'creado'} de manera exitosa`);
      setOpenModalSuccess(true);
    } else {
      setErrorMessage(
        `Hubo un error al ${section ? 'actualizar' : 'crear'} la sección, intente de nuevo mas tarde`
      );
      setOpenModalError(true);
    }


  };

  const updateAssociated = async (sectionQuestion: IQuestionSection) => {
    if (sectionQuestion.id > 0) {
      const questionToSend = {

        "required": sectionQuestion.required,
        "order": sectionQuestion.order,
        "sectionId": sectionQuestion.sectionId,
        "id": sectionQuestion.id,
        "questionId": sectionQuestion.questionId
      }

      const resultUpdateAssociate: Response = await trackPromise(updateassociatedQuestion(questionToSend)) as Response;

      if (resultUpdateAssociate.status === 200) {
        console.log("ok")
      } else {
        setErrorMessage(
          `Hubo un error al asociar la pregunta, intente de nuevo mas tarde`
        );
        setOpenModalError(true);
      }
    }
  };


  const associate = async (section: ISection, questionToAssociate: IQuestion) => {
    if (questionToAssociate.id > 0) {
      const questionToSend = {
        "required": false,
        "order": currentQuestions.length + 1,
        "sectionId": section.id,
        "questionId": questionToAssociate.id,
        "redirect": [{'respuesta': 123, 'seccion': 1}, {'respuesta': 123, 'seccion': 2}]

      }

      const resultAssociate: Response = await trackPromise(associateQuestion(questionToSend)) as Response;

      if (resultAssociate.status === 200) {
        if (selectedSection) {
          loadCurrentQuestions(selectedSection.id);
        }

        const newQuestionList = questionFiltered.filter((question) => {
          return question.id !== questionToAssociate.id;
        });

        setSelectedQuestion(new IQuestion({}));
        setQuestionFiltered(newQuestionList);
        setSuccessMessage(`La pregunta se ha agregado de manera exitosa`);
        setOpenModalSuccess(true);
      } else {
        setErrorMessage(
          `Hubo un error al asociar la pregunta, intente de nuevo mas tarde`
        );
        setOpenModalError(true);
      }
    }
  };

  const questionHasFlow=(question: IQuestion):boolean=>{
    return (TYPES.find(item => question.type===item.value) || {flow:false}).flow;
  }

  
  const onChangeFlows=async(event:any, flowQuestion: IQuestionSection, answer:string)=>{
    let currentFlows = flowQuestion.redirect;
    if (flowQuestion.id > 0) {
      console.log(flowQuestion, answer, event.target.value)
      const questionsWithFlows = currentQuestions.map(question =>{
        if(flowQuestion.id===question.id){
            const flows:RedirectionFlow[] = question.redirect.map((choice:RedirectionFlow) =>{
              if(answer===choice.answer){
                choice.tagetSectionId=event.target.value
              }
              return choice;
            });
            question.redirect = flows;
            currentFlows = flows;
            return question;
        }
        return question;
      });

      const questionToSend = {
        "sectionId": flowQuestion.sectionId,
        "id": flowQuestion.id,
        "questionId": flowQuestion.questionId,
        "redirect": currentFlows
      }

      const resultUpdateAssociate: Response = await  trackPromise(updateassociatedQuestion(questionToSend)) as Response;

      if (resultUpdateAssociate.status === 200) {
        setCurrentQuestions(questionsWithFlows);
      } else {
        setErrorMessage(
          `Hubo un error al seleccionar el fli, intente de nuevo mas tarde`
        );
        setOpenModalError(true);
      }
    }
  }


  const disassociate = async (questionSection: IQuestionSection) => {
    if (questionSection.id > 0) {
      const questionToSend = {
        "id": questionSection.id
      }

      const resultAssociate: Response = await trackPromise(disassociateQuestion(questionToSend)) as Response;

      if (resultAssociate.status === 200) {
        if (selectedSection) {
          loadCurrentQuestions(selectedSection.id);
        }

        setSuccessMessage(`La pregunta se ha eliminado de manera exitosa`);
        setOpenModalSuccess(true);
      } else {
        setErrorMessage(
          `Hubo un error al desvincular la pregunta, intente de nuevo mas tarde`
        );
        setOpenModalError(true);
      }

    }

  };


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <>
      <Grid className="section-form">
        <Grid className="section-container2">
          {formValues && (formValues || []).length > 0 &&
            (formValues || []).map((section, index) => (
              <Accordion key={"sec-" + index} className="title-container" expanded={index === displayedSection} >
                <AccordionSummary onClick={() => { handleChangeSection(index, section) }} expandIcon={<ExpandMoreIcon className="expand_icon"></ExpandMoreIcon>} id={"panel-" + index}>
                  <Typography variant="h2" className="title">
                    {formValues[index].title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="section-content2">
                  <Grid container item xs={12} direction="row" >
                    <Grid item xs={12} className="field-box-margin">
                      <Typography variant="h5" className="field-box-title">
                        Enunciado*
                      </Typography>
                      <TextField
                        fullWidth
                        name="title"
                        placeholder="Enunciado "
                        multiline
                        rows={1}
                        variant="outlined"
                        value={formValues[index].title || ''}
                        onChange={handleChange(index)}
                        error={!!formErrors.title}
                        helperText={formErrors.title}
                        margin="none"
                      />
                    </Grid>

                    <Grid item xs={12} className="field-box-margin">
                      <Typography variant="h5" className="field-box-title">
                        Descripción
                      </Typography>
                      <TextField
                        fullWidth
                        name="description"
                        placeholder="Descripción "
                        multiline
                        rows={5}
                        variant="outlined"
                        value={formValues[index].description || ''}
                        onChange={handleChange(index)}
                        error={!!formErrors.description}
                        helperText={formErrors.description}
                        margin="none"
                      />
                    </Grid>
                    <Grid container item xs={12} className="buttons-container-section">
                      <Grid container item xs={12} md={12} className="right-buttons">
                        <Button disabled={!isValid(formValues[index])} className="save-button" variant="contained" size="small"
                          onClick={(e) => { update(formValues[index]); }}>Actualizar</Button>
                      </Grid>
                    </Grid>
                    <Grid container item xs={12} className="line" />


                    <Grid container item xs={12} >
                      <Grid item xs={isSmallScreen ? 12 : 6} className="field-box-margin">
                        <Typography variant="h4" className="field-box-title">
                          Agregar preguntas a la sección
                        </Typography>
                        <Typography variant="h5" className="field-box-title">
                          Listado de preguntas*
                        </Typography>
                        <Autocomplete
                          value={selectedQuestion}
                          id="combo-box-demo"
                          options={questionFiltered}
                          onChange={handleChangeQuestion}
                          getOptionLabel={(option) => option.title}
                          renderInput={(params) => <TextField {...params}
                            placeholder="Elegir pregunta " variant="outlined" />} />


                        <Grid className="associate-container">
                          <Button disabled={disabledAssociate} className={disabledAssociate ? "associate-disabled-button" : "associate-button"}
                            variant="contained" endIcon={<AddIcon />} onClick={(e) => { associate(formValues[index], selectedQuestion); }}>
                            <Typography variant="h6" className="regular-serif">Asociar pregunta</Typography>
                          </Button>
                        </Grid>
                      </Grid>


                      <Grid item xs={isSmallScreen ? 12 : 6} className="field-box-margin">

                        <Typography variant="h4" className="field-box-title">
                          Crear preguntas
                        </Typography>
                        <Grid className="associate-container">
                          <Button variant="contained" className="associate-button" endIcon={<AddIcon />}
                            onClick={(e) => { handleClickOpen(formValues[index]); }}>
                            <Typography variant="h6" className="regular-serif">Agregar pregunta</Typography>
                          </Button>
                        </Grid>

                      </Grid>
                    </Grid>
                    <Grid container item xs={12} className="line" />
                    <Grid container item xs={12} className="field-box-margin">

                      <Typography variant="h5" className="field-box-title">
                        Listado de preguntas de la sección
                      </Typography>

                      <Grid item xs={12}>
                        <Typography variant="h5" className="field-box-text">Preguntas</Typography>
                      </Grid>
                    </Grid>

                    {currentQuestions && currentQuestions.map((question, index) => (
                      <>
                      <Grid key={question.id} container item xs={12} className="field-box-margin">
                        <Grid item xs={5}>
                          <Typography variant="h5" className="field-box-text">{(index + 1) + ". " + question.Questions.title}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={question.required}
                                onChange={() => { handleChangeRequired(question) }}
                                name={"checked" + question.id}
                                color="primary"
                              />
                            }
                            label="Obligatoria"
                          />
                        </Grid>
                        <Grid spacing={1} container item xs={2}>
                          {index !== (currentQuestions.length - 1) ?
                            <Grid item>
                              <IconButton className="border_icon" size={isSmallScreen ? "small" : "medium"}
                                onClick={() => { handleChangeOrder(question, OrderDirection.UP) }}>
                                <ArrowCircleDownIcon fontSize="inherit" />
                              </IconButton ></Grid> : null
                          }

                          {index !== 0 ? <Grid item><IconButton className="border_icon" size={isSmallScreen ? "small" : "medium"}
                            onClick={() => { handleChangeOrder(question, OrderDirection.DOWN) }}>
                            <ArrowCircleUpIcon fontSize="inherit" />
                          </IconButton ></Grid> : null}
                        </Grid>


                        <Grid container item xs={3} className="buttons-container-section">
                          <Grid container className="right-buttons">
                            <Button style={{ display: !isSmallScreen ? undefined : 'none' }} onClick={(e) => { disassociate(question); }}
                              className="delete-question-button" variant="outlined"
                              startIcon={<DeleteIcon />}>
                              <Typography variant="h6"  >
                                Eliminar Pregunta
                              </Typography>
                            </Button>

                            <IconButton size="small" style={{ display: isSmallScreen ? undefined : 'none' }}
                              className="delete-question-button" onClick={(e) => { disassociate(question); }}>
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>

                      </Grid>

                      {questionHasFlow(question.Questions)?<Grid spacing={1} container item xs={12}>
                      <Grid item xs={12} className="field-box-margin"  onClick={()=>{expandReduce(question.id.toString())}} style={{cursor:'pointer'}} >
                        <Typography variant="h6" className="field-box-title" >
                          Flujos de respuesta (Opcional) {expandedFlows.includes(question.id.toString())?<ExpandLessIcon/>: <ExpandMoreIcon/> }
                        </Typography>
                      </Grid>
                      {expandedFlows.includes(question.id.toString()) && (question.redirect as RedirectionFlow[]).map((element:RedirectionFlow) => {
                        return <Grid key={question.id} container item xs={12} className="field-box-margin">
                        
                          <Grid item xs={6}>
                              <Typography variant="h5" className="field-box-text">{element.answer}</Typography>
                          </Grid>
                          <Grid container item xs={3} className="buttons-container-section">
                          <Grid container className="right-buttons">
                          <TextField
                                    fullWidth
                                    id="outlined-select-type"
                                    select
                                    
                                    value={element.tagetSectionId}
                                    onChange={(event)=>{
                                      onChangeFlows(event, question, element.answer)
                                    }}
                                    InputLabelProps={{ shrink: false }}
                                    margin="none"
                                    variant="outlined"
                                  >
                                    <MenuItem key={-1} value={-1}>
                                      Sin Flujo 
                                    </MenuItem>
                                    {formValues && formValues.map(sect => {
                                        if(sect.id!==section.id){
                                          return <MenuItem key={sect.id} value={sect.id}>
                                            {sect.title}
                                          </MenuItem>
                                        }
                                        return null;
                                    })}
                                  </TextField>
                          </Grid>
                        </Grid>
                        
                        </Grid>
                        })}
                        </Grid>:null}
                      <Grid container item xs={12} className="line" />

                      </>
                    ))}






                    <Grid container item xs={12} className="buttons-container-section">
                      <Grid container item xs={12} md={12} className="center-buttons">
                        <Button onClick={() => confirmDeleteSection(formValues[index])} className="delete-button" endIcon={<DeleteIcon />} >
                          <Typography variant="h6" className="regular-serif">
                            Eliminar
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

            ))}
        </Grid>
        <Grid container item xs={12} className="buttons-container-section">
          <Grid container item xs={12} md={12} className="right-buttons">
            <Button className="save-button" variant="contained" size="small" endIcon={<AddIcon />}
              onClick={(e) => { update(); }} >Agregar sección</Button>
          </Grid>
        </Grid>
      </Grid>

      <ResponsiveBasicModal
        open={openModalQuestion}
        handleClose={handleCloseEdit}
        children={
          <QuestionForm
            action="Create"
            backActionOverride={handleCloseEdit}
            afterUpdateAction={updateQuestions}
          ></QuestionForm>
        }
      />
      <SuccessModal
        successMsg={successMessage}
        handleCloseModal={handleCloseModalSuccess}
        openModalSuccess={openModalSuccess}
      />
      <ErrorModal
        errorMsg={errorMessage}
        handleCloseModal={handleCloseModalError}
        openModalError={openModalError}
      />
      <ModalOffersAction
        open={opeModalConfirm}
        handleClose={closeModalConfirm}
        handleContinue={removeSection}
        iconModal={<WarningIcon className="big-warning-icon" />}
        title="¿Esta seguro de eliminar la sección?"
        headerdescription=""
        description="Una vez eliminada no podrá recuperarla"
        backBtnText="Regresar"
        colorBtnBack=""
        continueBtnText="Eliminar"
        colorBtnContinue="#f44336"
        valid={true}
        notLines={true}
      />
      {/* <LoadingLockPage load={initLoad}/> */}
      <LoadingLockPage load={promiseInProgress} />
    </>
  );
};

export default SectionForm;
