import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { Grid, Typography } from '@material-ui/core';
import UserContext from '../../../../utils/UserContext';
import { isLogin } from '../../../../services/AuthService';
import SurveysAdmin from '../SurverysAdmin';

import './SurveysManagement.scss';
import SurveyStateBar from '../../../../components/Survey/SurveyStateBar';
import QuestionAdmin from '../QuestionAdmin';
import { permissionAdminSurveys } from '../../../../utils/PermissionsVariables';
import { containsAny } from '../../../../helpers/sharedFunctions';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';

const SurveysAdminManagement: React.FC = () => {
  const userContext = React.useContext(UserContext);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const isLogg = isLogin();

  useEffect(() => {
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if (urlPath.includes('banco-preguntas') ) {
      setSelectedSection('banco-preguntas');
      userContext.setStateSurveySection('banco-preguntas');
    } else {
      setSelectedSection('encuestas');
      userContext.setStateSurveySection('encuestas');
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href])

  return (
    <>
      {isLogg && containsAny(permissionAdminSurveys, userContext.userPermissions)  ?
        <>
          <Grid item xs={12} className="state-bar-section">
            <SurveyStateBar selected={selectedSection} />
          </Grid>
          {selectedSection === "banco-preguntas" &&
            <Grid container item xs={12}>
              <QuestionAdmin/>
            </Grid>
          }
          {selectedSection === "encuestas" &&
            <Grid container item xs={12}>
              <SurveysAdmin />
            </Grid>
          }
        </>
        : isLogg?<NotAuthorized/>:
        <Redirect to="/" />
      }
          </>
  )
}

export default SurveysAdminManagement
