import moment from 'moment';
import 'moment/locale/es'
const isText = RegExp(/^[a-zA-ZñÑÀ-ÖØ-öø-ÿ ]+$/i); // text field
const includeCapital = RegExp(/[A-Z]/); // Capital letters
const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i); // email
const isNumber = RegExp(/^\d+$/); // number field
const hasNumberReg = /\d/;
const isUrl = RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");
// const has_whitespaces = RegExp(/^(?![\s-])[\w\s-]+$/);
const has_whitespaces = RegExp(/^(?![\s-])+[a-zA-ZñÑÀ-ÖØ-öø-ÿ ]+[\w\s-]+$/);
const special_char = RegExp(/^[^ -._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+$/);

const Validations = (name:string, value: any, schema: any)=>{
    const { validate, required, minLength, maxLength, minDate, maxDate, minTime } = schema[name];
    let error = "";
    let hasCapital = false;
    let hasNumber = false;
    let countLetters = 0;
    if(name === "password" || name === "secondPassword"){
        hasCapital = includeCapital.test(value);
        hasNumber = hasNumberReg.test(value);
        for (let i=0; i<value.length; i++)
        {
            if(isText.test(value[i])){
                countLetters +=1;
            }
        }
    }
    // validate minLength
    if (value.length > 0 && value.length < minLength) error = `Mínimo ${minLength} caracteres son requeridos.`
    // validate maxLength
    else if (maxLength && value.length > maxLength) error = `Maximo de longitud de ${maxLength} excedido!`

    // validate required
    if (!value && required && validate !== 'specialCheckbox') {
        error = `Este campo es obligatorio`
    } else {
        if (!validate) return
        switch (validate) {
            case "text":
                if (value && !isText.test(value)) error = "Este campo solo acepta texto."
                break

            case "number":
                if (value && !isNumber.test(value)) error = "Este campo solo acepta numeros."
                break

            case "email":
                if (value && !isEmail.test(value))
                { error = "Por favor ingrese un correo valido."}
                break

            case "checkbox":
                if (!value && required) error = "Por favor seleccione un valor."
                break

            case "select":
                if (!value && required) error = "Por favor seleccione un valor."
                break

            case "website":
                if (value && !isUrl.test(value)) error = "Por favor ingrese una dirección valida (http://www., https://www.)."
                break

            case "textNumber":
                break

            case "specialDate":
                const valueDate = new Date(value)
                const validDate = moment(valueDate).isValid();
                if(value){
                    if(validDate){
                        if(valueDate > maxDate){
                            const maxDateString = moment(maxDate).format('MMMM YYYY');
                            error = `Ingrese una fecha menor o igual a ${maxDateString}`
                        } else if (valueDate < minDate){
                            const minDateString = moment(minDate).format('MMMM YYYY');
                            error = `Ingrese una fecha mayor o igual a ${minDateString}`
                        }
                    } else {
                        error  = "Fecha invalida"
                    }
                }
                break

            case "normalDate":
                const valDate = moment(new Date(value)).format("YYYY-MM-DD");
                const valMaxdate = maxDate ? moment(maxDate).format("YYYY-MM-DD") : null;
                const valMinDate = minDate ? moment(minDate).format("YYYY-MM-DD") : null;
                const correctDate = moment(valDate).isValid();
                if(value){
                    if(correctDate){
                        if(valMaxdate && valDate > valMaxdate){
                            const maxDateString = moment(maxDate).format('DD/MM/YYYY');
                            error = `Fecha maxima ${maxDateString}`
                        }
                        if (valMinDate && valDate < valMinDate){
                            const minDateString = moment(minDate).format('DD/MM/YYYY');
                            error = `Ingrese fecha mayor o igual a ${minDateString}`
                        }
                    } else {
                        error  = "Fecha invalida"
                    }
                }
                break

            case 'normalTime':
                const minTimeValue = moment(new Date(minTime)).format();
                const enterTime = moment(new Date(value)).format();
                const correctTime = moment(value).isValid();
                if(correctTime){
                    if(enterTime < minTimeValue){
                        const minTimeString = moment(new Date(minTime)).format('hh:mm a');
                        error = `Hora mínima ${minTimeString}`
                    }
                } else {
                    error  = "Hora invalida"
                }
            break

            case "password":
                if(!hasCapital || !hasNumber || countLetters<4){
                    error = "Contraseña invalida,  no cumple con las condiciones"
                }
                break

            case "secondPassword":
                if(!hasCapital || !hasNumber || countLetters<4){
                    error = "Contraseña invalida,  no cumple con las condiciones"
                }
                break
            case "specialText":
                if (value && !isText.test(value)) error = "Este campo solo acepta texto."
                else if (value && !has_whitespaces.test(value)) error = "Por favor no deje espacios al inicio y al final"
                else if (value && value.trim().length === 0) error = "Texto no valido"
                break
            case "specialChar":
                if (value && !special_char.test(value)) error = "Este campo no acepta caracteres especiales ni espacios"
                break

            case "specialCheckbox":
                
                if ((value === undefined || value === null) && required) error = "Por favor seleccione un valor."
                break
            default:
                break
        }
    }

    return error;
}

export default Validations