export class IGetQuestions{
    id: number = 0;
    title: string = "";
    active: boolean = true;
    category: string = "";
    type: string = "";
    description: string="";
    name: string = "";
    jsonText: string = "";
    user: string = "";
    createdAt?: string = "";
    updatedAt?: string = "";
    updateField?: number;
    updateSystem?:boolean

  }