import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './ResponsiveBasicModal.scss';
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#004B73'
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className="offer-dialog-header" {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ResponsiveBasicModalProps {
  open: any;
  handleClose: any;
  children?: any;
}

const ResponsiveBasicModal: React.FC<ResponsiveBasicModalProps> = ({
  open,
  handleClose,
  children,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <>
        <Dialog
          style={{"height":"100%"}}
          fullScreen={isSmallScreen}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth={true}
          disableBackdropClick
          maxWidth="xl"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} />
          <DialogContent >
            <Grid container className="main-container">
              <Grid container item xs={12} className="question-container">
                <Grid container>{children}</Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    </>
  );
};

export default ResponsiveBasicModal;
