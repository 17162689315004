export class IAnswer{
  id: number;
  order: number;
  title: string;

  constructor(def:any){
    this.id = def.id || null;
    this.order = def.order || "";
    this.title = def.title || "";      
  }
}