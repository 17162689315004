import { useEffect, useState } from 'react';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import { getSurveQuestions } from '../../../services/SurveyServices';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import { customCss, themeJson } from '../SurveyCommon/survey-common-theme';
import './custom-locale';
import '../SurveyCommon/SurveyCommon.scss'
import { generateSurveyJSON } from '../SurveyComponent/SurveyComponent';
import "survey-core/i18n/spanish";
import { Grid, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';

interface SurveyPreviewProps {
  surveyInfo: IGetSurveys;
}

const SurveyPreview: React.FC<SurveyPreviewProps> = ({ surveyInfo }) => {
  const { promiseInProgress } = usePromiseTracker();
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalError, setOpenModalError] = useState(false);
  const [model, setModel] = useState<Model>(new Model({ elements: [{}] }));

  const loadQuestions = async () => {
    if (surveyInfo.id) {
      const result: Response = (await trackPromise(getSurveQuestions(surveyInfo.id))) as Response;
      if (result.status === 200) {
        const sections = (await result.json()).message;

        const customModel = new Model(generateSurveyJSON(sections));
        customModel.applyTheme(themeJson);
        customModel.locale = "es";
        customModel.css = customCss;
        setModel(customModel);
        //const surveyInfoLocal: IGetSurveys = new IGetSurveys((await result.json()).survey);
      } else {
        setErrorMessage(
          'Hubo un error al consultar los datos de la pregunta, intente de nuevo mas tarde'
        );
        setOpenModalError(true);
      }
    }
  };

  useEffect(() => {
    loadQuestions();
  }, []);

  return (
    <div className="survey-container">
    <Grid container item xs={12} className="back-offers-list-section survey-header">
      <Grid container item xs={12} md={12} lg={12}>
        <div className="title-section-line">
          <DescriptionIcon className="group-icon" />
          <Typography variant="h2" className="survey-title">
            {surveyInfo?.title}
            <Typography variant="subtitle2" gutterBottom={false}>
              {surveyInfo?.description}
            </Typography>
          </Typography>
        </div>
      </Grid>
    </Grid>
    <Survey showCompleteButton={false} model={model} />
    </div>
    
  );
};

export default SurveyPreview;
