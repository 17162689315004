import { IGetQuestions } from "./IGetQuestions";

export class IQuestion{
  id: number;
  active: boolean;
  title: string;
  type: number;
  user: string;
  description: string;
  category: number;
  jsonText: any;
  createdAt: string;
  updatedAt: string;
  updateField?: number;
  updateSystem?: boolean



  constructor(def:any){
    this.id = def.id || null;
    this.active = def.active || "";
    this.title = def.title || "";
    this.type = def.type || "";
    this.description = def.description || "";
    this.category = def.category || "";
    this.user = def.user || "";
    this.jsonText = def.json_text || "";
    this.createdAt = def.createdAt || "";
    this.updatedAt = def.updatedAt || "";       
    this.updateField = def.updateField || 0 
    this.updateSystem =def.updateSystem || false
  }

  static fromGetQuestons(question:IGetQuestions){
    return new IQuestion({
      id: question.id,
      active: question.active,
      title: question.title,
      type: question.type,
      description: question.description,
      category: question.category,
      user: question.user,
      jsonText: question.jsonText,
      createdAt: question.createdAt,
      updatedAt: question.updatedAt,
      updateField: question.updateField,
      updateSystem: question.updateSystem
    });
  }
}