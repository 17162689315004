import React, { useState } from 'react'
import Grid from "@material-ui/core/Grid";
import { Link as LinkTo } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from "@material-ui/core/Typography";
import imgHomeEmpresas from '../../../assets/images/IMG_HOME_EMPRESAS.png';
import RegisterModal from '../../ShareComponents/RegisterModal';
import './RegisterCompanyBox.scss'


const RegisterCompanyBox: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
      <Grid container className="landing-company-container" >
        <Grid container item xs={12} md={6} >
          <Grid container className="first-part">
            <img src={imgHomeEmpresas} alt="registro empresas" className="company-img"/>
            <Grid container item md={6} className="text-container">
              <Typography variant="h1" className="title-container regular-serif">
                  Servicios para empresas
              </Typography>
              <Typography variant="h5" className="register-text regular-serif">
                En esta sección puede consultar el estado del registro de su empresa, informarse sobre los servicios para empresas que la Universidad Nacional de Colombia ofrece.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="column" xs={12} className="container-btn">
            <Button variant="contained" className="register-btn" size="small" onClick={(handleClickOpen)}>
                <Typography variant="h6" className="regular-serif">
                    Registrarse
                </Typography>
            </Button>
            <RegisterModal
              open={open}
              handleClose={handleClose}
              title="Registro de empresas"
              subtitle="Estimado(a) Empresario(a):"
              description="Bienvenido(a) al Sistema de Información de Egresados de la UNAL. Si usted desea inscribirse en nuestra Red de Apoyo Laboral para presentar ofertas de empleo, participar en eventos y recibir información institucional, ingrese al formulario de Registro. Una vez obtenga su Usuario y Clave, deberá conservarlos para ingresar al sistema sin tener que registrarse nuevamente."
              backBtnText="REGRESAR"
              colorBtnBack=""
              continueBtnText="IR AL REGISTRO"
              continueBtnColor=""
              linkContinue="/empresa/registro"
            />
            <Button variant="contained" className="consult-btn" size="small" component ={ LinkTo } to="/consulta-estado">
              <Typography variant="h6" className="regular-serif">
                Consultar el estado de su registro
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid  container item  xs={12} md={6} direction="column"  className="general-box">
          <Grid >
            <Link target="_blank" rel="noopener" href={'https://egresados.unal.edu.co/index.php/quienes-somos/normatividad'}>
              <Typography variant="h1" className="column-link">
                Normatividad
              </Typography>
            </Link>

            <Link target="_blank" rel="noopener" href={'https://egresados.unal.edu.co/images/Normatividad/2020_REGLAMENTO_BOLSA_DE_EMPLEO.pdf'}>
              <Typography variant="h1" className="column-link">
                Reglamento bolsa de empleo
              </Typography>
            </Link>
            <Link target='_blank' rel='noopener' href={'https://drive.google.com/drive/folders/1mNcCm7ZJKeEz2w0iK7MzAJzOtfTUc32H?usp=sharing'}>
              <Typography variant="h1" className="column-link">Instructivos</Typography>
            </Link>
            {/* <Typography component ={ LinkTo } to="/ofertas" variant="h1" className="column-link">Ofertas laborales</Typography> */}
          </Grid>
        </Grid>
      </Grid>
  )
}

export default RegisterCompanyBox
