import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import LoginBox from '../../components/LandingPage/LoginBox/index';
import RegisterBox from '../../components/LandingPage/RegisterBox/index';
import CheckStateBox from '../../components/LandingPage/CheckStateBox/index';
import RegisterCompanyBox from '../../components/LandingPage/RegisterCompanyBox/index';
import NewsEventsBox from '../../components/LandingPage/NewsEventsBox';
import ChatButton from '../../components/LandingPage/ChatButton';
import './landing.scss';

const Landing: React.FC = ()=>{
    return(
        <>
            <Grid container className="landing-container">
                <Grid  item xs={12}  >
                    
                    <LoginBox/>
                    <RegisterBox/>
                    <CheckStateBox/>
                    <RegisterCompanyBox/>
                    <NewsEventsBox/>
                    <ChatButton />
                    
                </Grid>
            </Grid>
        </>
    )
}

export default Landing
