import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './GraduateFillSurvey.scss';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Model } from 'survey-core';
import {
  themeJson,
  customCss
} from '../../../../components/Survey/SurveyCommon/survey-common-theme';
import {
  getSurveQuestions,
  getSurveyHeader,
  isAlreadyAnswered,
  respondSurvey
} from '../../../../services/SurveyServices';
import { Survey } from 'survey-react-ui';
import { IGetSurveys } from '../../../../interfaces/IGetSurveys';
import { Button, Grid, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import AlertBox from '../../../../components/ShareComponents/AlertBox';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IPreSignedUrlReq, IPresignedUrl } from '../../../../interfaces/IPreSignedUrl';
import { getSignUrl, uploadFileSignedUrl } from '../../../../services/s3Service';
import UserContext from '../../../../utils/UserContext';
import { generateSurveyJSON } from '../../../../components/Survey/SurveyComponent/SurveyComponent';
import "survey-core/i18n/spanish";

const GraduateFillSurvey: React.FC = () => {
  let params: any = useParams();
  
  const { promiseInProgress } = usePromiseTracker();
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalError, setOpenModalError] = useState(false);
  const [model, setModel] = useState<Model>(new Model({ elements: [{}] }));
  const [sections, setSections] = useState<any>();
  const [surveyInfo, setSurveyInfo] = useState<IGetSurveys>();
  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);
  const [enable, setEnable] = useState(true);
  let  [surveyFiles, setSurveyFiles] = useState<{file:File, name:string}[]>([]);
  const userContext = React.useContext(UserContext);
  const isValid = (survey: IGetSurveys) => {
    var startDate = new Date(survey?.startDate != null ? survey?.startDate : new Date());
    var endDate = new Date(survey?.endDate != null ? survey?.endDate : new Date());
    var today = new Date();

    if (survey?.status !== 'Publicada' || (today > endDate || today < startDate)) {
      setEnable(false);
      setErrorMessage(
        'Esta encuesta aún no ha sido publicada'
      );
      setOpenModalError(true);
    }

  }


  const saveFile= async (question: any, answer: any, path:string) =>{
    const fileKey=`${params.id}/${question.id}/${userContext.userId}/${answer.name}`
    const fileToUpload: IPreSignedUrlReq = {
      contentType: answer.type,
      fileKey: fileKey,
      folderId: `survey-files`,
      description: ""
    }
    const resultURL: IPresignedUrl = await trackPromise(getSignUrl(fileToUpload)) as IPresignedUrl;
    if(resultURL){
      const urlPresigned = resultURL.uploadURL;
      const file = surveyFiles.find(f=> f.name===`pregunta${question.id}`)
      if(file && file.file){
          await trackPromise(uploadFileSignedUrl(urlPresigned, file.file)) as Response;
      }
    }
  }

  const onValueChanged = (sender:any, options:any) => {
    if(options.question.constructor.name==="QuestionFileModel"){
      const file = (document.querySelectorAll(`[data-key="${options.name}0"] input[type="file"]`)[0] as any).files[0];
      const files = [{name: options.name, file: file}, ...surveyFiles]
      setSurveyFiles(files)
    }
  };

  const onCompleted = async(sender:any, options:any) => {
    const flatQuestions = sections.flatMap((section: any) => {
      return section.SectionQuestions.map((question: any) => {
        return question.Questions;
      });
    });

    const answers = flatQuestions.map((question: any) => {
      let key =""
      if(question.type===8){
        const fileAnswer = sender.data[`pregunta${question.id}`][0];
        if(fileAnswer.content){
          key = `${params.id}/${question.id}/${userContext.userId}/${fileAnswer.name}`
          saveFile(question, fileAnswer, key);    
        }
      }
      return {
        answer_json: {
          answer: question.type===8?`survey-files/${key}`:sender.data[`pregunta${question.id}`],
          options:
            question.jsonText.rows && question.jsonText.columns
              ? { rows: question.jsonText.rows, columns: question.jsonText.columns }
              : question.jsonText.choices || []
        },
        question_type: question.type,
        question_text: question.title
      };
    });

    const respondSurveyResult: Response = (await trackPromise(
      respondSurvey({
        answers: answers,
        surveyId: params.id
      })
    )) as Response;

    if (respondSurveyResult.status === 200) {
      setFinished(true);
    } else {
      setErrorMessage(
        'No ha sido posible enviar sus respuestas, intente de nuevo mas tarde'
      );
      setOpenModalError(true);
    }

  }

  const loadQuestions = async () => {
    if (params.id) {
      const resultAnswered: Response = (await trackPromise(isAlreadyAnswered(params.id))) as Response;
      if(resultAnswered.status === 200){
        const isAnswered = (await resultAnswered.json()).completed
        if(!isAnswered){
          const resultSurvey: Response = (await trackPromise(getSurveyHeader(params.id))) as Response;
          if (resultSurvey.status === 200) {
            const surveyInfoLocal: IGetSurveys = new IGetSurveys((await resultSurvey.json()).survey);
    
            setSurveyInfo(surveyInfoLocal);
    
            isValid(surveyInfoLocal);
    
            if (!enable) {
              return;
            }
    
          } else {
            setErrorMessage(
              'Hubo un error al consultar los datos de la encuesta, intente de nuevo mas tarde'
            );
            setOpenModalError(true);
          }
    
          const resultSections: Response = (await trackPromise(
            getSurveQuestions(params.id)
          )) as Response;
          if (resultSections.status === 200) {
            const sections = (await resultSections.json()).message;
            const customModel = new Model(generateSurveyJSON(sections));
            customModel.locale = 'customlocale';
            customModel.applyTheme(themeJson);
            customModel.css = customCss;
            customModel.completedHtml = 'Gracias por completar esta encuesta';
            customModel.locale = "es";

            setSections(sections);
            
            setModel(customModel);
          } else {
            setErrorMessage(
              'Hubo un error al consultar los datos de la encuesta, intente de nuevo mas tarde'
            );
            setOpenModalError(true);
          }  

        } else {
          setStarted(true)
          setFinished(true);
          setAlreadyAnswered(true)
        }



      }

    }
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
  };


  useEffect(() => {
    loadQuestions();
  }, []);

  return (
    <div className="survey-container">
      <Grid container item xs={12} className="back-offers-list-section survey-header">
        <Grid container item xs={12} md={3} lg={3}>
          <div className="title-section-line">
            <DescriptionIcon className="group-icon" />
            <Typography variant="h2" className="survey-title">
              {surveyInfo?.title}
              <Typography variant="subtitle2" gutterBottom={false}>
                {surveyInfo?.description}
              </Typography>
            </Typography>
          </div>
        </Grid>
      </Grid>

      {started ? (
        <>
          {finished ?
            <div className="banner-container">
              <Typography variant="h2" className="survey-title">
                <Grid container item xs={12}>
                  <AlertBox title="Información" alert="info" variantType="outlined">
                    <span className="alert-simple-text">
                      {alreadyAnswered?"Ya contestate está encuesta. ":null} Tu opinión es muy importante para nosotros!
                    </span>
                  </AlertBox>
                </Grid>
              </Typography>

              {surveyInfo?.endBanner ? <Grid item xs={12} className="card-image-container">
                <Grid container item xs={12} justify="flex-end" className="card-image-content">
                  <a href={surveyInfo?.endBannerUrl}>
                    <img
                      src={surveyInfo?.endBanner}
                      alt="banner de inicio"
                      className="image-noticia"
                    />
                  </a>
                </Grid>
              </Grid> : null}
            </div> : <Survey model={model} onValueChanged={(sender:any, options:any) => onValueChanged(sender, options)}  onComplete={(sender:any, options:any) => onCompleted(sender,options)}/>
          }
          
        </>
      ) : (
        <div className="banner-container">
          <Typography variant="h2" className="survey-title">
            <Grid container item xs={12}>
              <AlertBox title="Información" alert="info" variantType="outlined">
                <span className="alert-simple-text">
                  {enable ? 'Hola! has sido invitado a participar en una de nuestras encuestas. Para iniciar por favor has click en el botón "Responder encuesta"' : 'Esta encuesta aún no ha sido publicada'}
                </span>
              </AlertBox>
            </Grid>
          </Typography>

          {surveyInfo?.startBanner ? <Grid item xs={12} className="card-image-container">
            <Grid container item xs={12} justify="flex-end" className="card-image-content">
              <a href={surveyInfo?.startBannerUrl}>
                <img
                  src={surveyInfo?.startBanner}
                  alt="banner de inicio"
                  className="image-noticia"
                />
              </a>
            </Grid>
          </Grid> : null}

          <Grid container item xs={12} className="buttons-setting-survey">
            <Grid container item xs={12} md={6} className="right-buttons">
              <Button disabled={!enable}
                className="save-button"
                variant="contained"
                onClick={() => {
                  setStarted(true);
                }}
                size="small"
              >
                Responder Encuesta
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
      <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
      {/* <LoadingLockPage load={initLoad}/> */}
      <LoadingLockPage load={promiseInProgress} />
    </div>
  );
};

export default GraduateFillSurvey;
