const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

export const getActiveQuestions = async (surveyId: number) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) + "" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/survey/question/list?active=true&surveyId=${surveyId}`,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                }
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const getQuestions = async () => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) + "" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/survey/question/list`,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                }
            }
        )
        return response;
    } catch (error) {
        return error
    }
}

export const createQuestion = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) + "" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/survey/question/create`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}


export const getQuestion = async (questionId: number) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) + "" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/survey/question/find-one/${questionId}`,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                }
            }
        )
        return response;
    } catch (error) {
        return error
    }
}


export const updateQuestion = async (bodyRequest: object) => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) + "" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/survey/question/update`,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                },
                body: JSON.stringify(bodyRequest)
            }
        )
        return response;
    } catch (error) {
        return error
    }
}


export const getCustomFields = async () => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
        token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY) + "" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/survey/question/fields-update`,
            {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'sie_token': token
                }
            }
        )
        return response;
    } catch (error) {
        return error
    }
}


