import React, { useState, useEffect } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import UserContext from '../../../../utils/UserContext';
import { usePromiseTracker } from 'react-promise-tracker';
import AddIcon from '@material-ui/icons/Add';
import { isLogin } from '../../../../services/AuthService';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import Validations from '../../../../helpers/Validations';
import { containsAny, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import { StateByRole } from '../../../../interfaces/StateByRole';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import { IGetSurveys } from '../../../../interfaces/IGetSurveys';
import SurveyList from '../../../../components/Survey/SurveyList';
import { getExportSurvey, getSurveys } from '../../../../services/SurveysService';

import SurveysFilters from '../../../../components/ManagementState/SurveysFilters/index';
import ShowFiltersApplied from '../../../../components/ManagementState/ShowFiltersApplied';

import { ChipData } from '../../../../interfaces/ChipData';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminSurveys } from '../../../../utils/PermissionsVariables';

import './SurveysAdmin.scss';
import ErrorModal from '../../../../components/ShareComponents/ErrorModal';


const typeSurveyOptions: Array<StateByRole> = [
  { id: 1, label: 'Todas', value: 'Todos', description: '', permission: [] },
  { id: 2, label: 'Publicada', value: 'Publicada', description: '', permission: [] },
  { id: 3, label: 'Sin publicar', value: 'Sin publicar', description: '', permission: [] },
]

const fieldsValidation = {
  searched: {
    error: "",
    validate: "",
    required: true
  }
};
const initialErrorsSearch = {
  searched: ''
}

const SurveysAdmin: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [initPage, setInitPage] = useState<number>(0);
  const [searched, setSearched] = useState('');
  const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
  const [searchActive, setSearchActive] = useState(false);
  const [data, setData] = useState<Array<IGetSurveys>>([]);
  const [dataFiltered, setDataFiltered] = useState<Array<IGetSurveys>>([]);
  const [surveySelected, setSurveySelected] = useState<IGetSurveys>(new IGetSurveys());
  const [updateReady, setUpdateReady] = useState<string>('');
  const [typeSurveyFilter, setTypeSurveyFilter] = useState<string>("Todos");
  const [chipData, setChipData] = useState<ChipData[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalError, setOpenModalError] = useState(false);

  useEffect(() => {
    const getListSurveys = async () => {
      const getInfo: Response = await trackPromise(getSurveys()) as Response;
      if (getInfo.status === 200) {
        let resultList = (await getInfo.json()).message;
        resultList = sortByKeyDesc(resultList, 'updatedAt');
        setData(resultList);
        setDataFiltered(resultList);
      }
    }
    getListSurveys();
  }, [])

  const handleUpdate = (value: string, surveySelected: IGetSurveys) => {
    setSurveySelected(surveySelected)
    setUpdateReady(value)
  }

  const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeSurveyFilter((event.target as HTMLInputElement).value);
  };

  const onChanhePageSize = (e: any) => {
    setRowsPerPage(e.target.value)
  }

  const applyFilters = () => {
    setChips('apply');
    filterData(searched);
  }

  const clearFilters = () => {
    setTypeSurveyFilter("Todos");
    setChips('clear');
    setSearched("");
    setDataFiltered(data);
    setSearchActive(false)
  }

  const filterData = (keyWord: string) => {
    let resultTypes: Array<IGetSurveys> = [];
    let resultKeyword: Array<IGetSurveys> = [];
    if (typeSurveyFilter.length > 0) {
      if (typeSurveyFilter === "Todos") {
        resultTypes = data;
      } else if (typeSurveyFilter === "Sin publicar") {
        resultTypes = data.filter(item => item.status != "Publicada")
      } else {
        resultTypes = data.filter(item => item.status === typeSurveyFilter)
      }
    } else {
      resultTypes = data;
    }
    if (keyWord.length > 0) {
      resultKeyword = resultTypes.filter(item => item.title.includes(keyWord) || item.description.includes(keyWord));
    } else {
      resultKeyword = resultTypes;
    }
    setInitPage(0);
    setDataFiltered(resultKeyword);
  }

  const setChips = (action: string) => {
    let tempArray: Array<ChipData> = [];
    if (action === "clear") {
      setChipData([]);
    } else {
      if (typeSurveyFilter !== '' && typeSurveyFilter !== null) {
        tempArray.push({ key: 1, name: 'type', label: 'Estado: ' + typeSurveyFilter })
      }
    }
    setChipData(tempArray);
  }

  const requestSearch = (name: string, searchVal: string) => {
    const error: string = searchVal !== '' ? Validations(name, searchVal, fieldsValidation) || "" : '';
    setFormErrorSearch(prev => ({
      ...prev,
      [name]: error
    }));
    setSearched(searchVal);
    if (searchVal !== '') {
      setSearchActive(true);
      filterData(searchVal)
    } else {
      setSearchActive(false);
      filterData('');
    }
  }

  const cancelSearch = () => {
    setSearched('')
    requestSearch('searched', '');
    setSearchActive(false)
  }


  const handleCloseModalError = () => {
    setOpenModalError(false);
  };

  const exportAnswers = async (surveyId: number) => {

    const getReportUrl: Response = await trackPromise(getExportSurvey(surveyId)) as Response;
  
    if (getReportUrl.status === 200) {

      let reportUrl = (await getReportUrl.json()).message;
    
      const fileResponse = await fetch(
            reportUrl
        )
      
      const blob = await fileResponse.blob();

      const a = document.createElement('a')
      a.download = "export.xlsx";
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt);
      a.remove();

    } else {
      let message : string = (await getReportUrl.json()).message;
      setErrorMessage('Error al descargar el archivo: '+ message);
      setOpenModalError(true);

    }
  }


  return (
    <>
      {isLogin() ?
        <>
          {containsAny(permissionAdminSurveys, userContext.userPermissions) ?
            <>
              {updateReady !== "" ?
                <Grid container >
                  {updateReady === "Settings" &&
                    <Redirect to={`/admin/dashboard/configurar-encuesta/${surveySelected.id}/header`} />
                  }
                  {updateReady === "Edit" &&
                    <Redirect to={`/admin/dashboard/editar-encuesta/${surveySelected.id}`} />
                  }
                </Grid>
                :
                <>
                  <Grid container className="main-container">
                    {containsAny(permissionAdminSurveys, userContext.userPermissions) &&
                      <Grid container item xs={12} className="survey-create-container">
                        <Button disabled={!containsAny(permissionAdminSurveys, userContext.userPermissions)} variant="contained" className="create-button" endIcon={<AddIcon />} component={Link} to="/admin/dashboard/crear-encuesta-pro">
                          <Typography variant="h6" className="regular-serif">Adicionar nueva encuesta</Typography>
                        </Button>
                      </Grid>
                    }
                    <Grid container item xs={12}>
                      <SurveysFilters title="Filtros encuestas" typeSurveyFilter={typeSurveyFilter} typeSurveyOptions={typeSurveyOptions} radioChange={radioChange} applyFilters={applyFilters} />
                    </Grid>
                    <Grid container item xs={12}>
                      <ShowFiltersApplied chipData={chipData} />
                    </Grid>
                    {chipData.length > 0 &&
                      <Grid container item xs={12} className="show-filters-chips">
                        <Button className="clear-btn" onClick={clearFilters}>
                          <Typography variant="h4" className="regular-serif">Limpiar filtros</Typography>
                        </Button>
                      </Grid>
                    }
                  </Grid>

                  <Grid container item xs={12} className="survey-list-container">
                    <Grid container className="main-container">
                      <Grid container className="list-table-container">
                        <Grid container item xs={12}>
                          <Typography variant="h2" className="title-survey-table-management">Encuestas</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <div className="header-table-container">
                            <div className="search-survey-container">
                              <SearchTable textPlaceHolder="Buscar por palabra clave" title="" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch} />
                            </div>
                          </div>
                        </Grid>
                        <Grid container item xs={12} className="list-container">
                          <Grid container item xs={12} className="list-view">
                            <SurveyList onChanhePageSize={onChanhePageSize} surveys={dataFiltered} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} handleUpdate={handleUpdate} exportAnswers={exportAnswers} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <ErrorModal
                    errorMsg={errorMessage}
                    handleCloseModal={handleCloseModalError}
                    openModalError={openModalError}
                  />
                  <LoadingLockPage load={promiseInProgress} />
                </>
              }
            </>
            :
            <NotAuthorized />
          }
        </>
        :
        <Redirect to="/" />
      }
    </>
  )
}

export default SurveysAdmin
