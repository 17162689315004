import React, { useState, useEffect } from 'react';

import 'moment/locale/es';
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link, Redirect } from 'react-router-dom';
import Validations from '../../../helpers/Validations';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import UserContext from '../../../utils/UserContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { IQuestion } from '../../../interfaces/IQuestion';
import './QuestionForm.scss';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from "@material-ui/core/styles";
import 'survey-core/defaultV2.css';


import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { IAnswer } from '../../../interfaces/IAnswer';
import AnswerForm from '../AnswerForm';
import { createQuestion, getCustomFields, getQuestion, updateQuestion } from '../../../services/QuestionService';
import SurveyComponent, { generateOptionsFromJson, TYPES } from '../SurveyComponent/SurveyComponent';
import { getQUESTION_CATEGORY } from '../../../services/ParameterService';
import { IGeneralParameter } from '../../../interfaces/IGeneralParameter';
import { ICustomField } from '../../../interfaces/ICustomField';
const initialFormValues = {
  title: '',
  description: '',
  type: '',
  category: '',
  updateField: '',
  updateSystem: false
};

const formValidations = {
  category: {
    error: '',
    validate: 'textNumber',
    minLength: 1,
    required: true
  },
  type: {
    error: '',
    validate: 'textNumber',
    minLength: 1,
    required: true
  },
  updateField: {
    error: '',
    validate: 'textNumber',
    minLength: 0,
    required: false
  },
  updateSystem:{
    error: '',
    validate: 'checkbox',
    minLength: 0,
    required: false
  },
  title: {
    error: '',
    validate: 'textNumber',
    maxLength: 500,
    minLength: 1,
    required: true
  },
  description: {
    error: '',
    validate: 'textNumber',
    maxLength: 500,
    minLength: 1,
    required: false
  }
};


interface QuestionFormProps {
  action?: string;
  backActionOverride?: any;
  questionSelected?: IQuestion;
  afterUpdateAction?: any
}

const QuestionForm: React.FC<QuestionFormProps> = ({
  action, questionSelected, backActionOverride, afterUpdateAction
}) => {
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState(new IQuestion({}));
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [answerList, setAnswerList] = useState<IAnswer[]>([]);
  const [criteriaList, setCriteriaList] = useState<IAnswer[]>([]);
  const [jsonText, setJsonText] = useState<string>();
  const [steps, setSteps] = useState(['Definir Pregunta', 'Previsualizar pregunta']);
  const [incrementStep, setIncrementStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [aux, setAux] = useState<boolean>(false);
  const [category, setCategory] = useState<{value:number; label: string}[]>([])
  const [customFields, setCustomFields] = useState<ICustomField[]>([]);

  const isValid = formValues.title.length > 0 && formValues.type > 0 && formValues.category > 0 && !formErrors.title && !formErrors.description;

  const isInvalidAnswer = () => {
    if ([3, 4, 5].includes(formValues.type) && answerList.length <= 0) {
      return true;
    } else if ([6, 7].includes(formValues.type)
      && (answerList.length <= 0 || criteriaList.length <= 0)) {
      return true;
    } else {
      return false;
    }
  }


  useEffect(() => {
    const getCompleteQuestion = async () => {
      if (questionSelected && action === 'Edit') {

        const result: Response = await trackPromise(getQuestion(questionSelected.id)) as Response;
        if (result.status === 200) {
          const target: IQuestion = new IQuestion({});
          let completedQuestion = (await result.json()).question;
          if(completedQuestion.updateSystem){
            completedQuestion.updateField = completedQuestion.FieldUpdate.id
          }
          completedQuestion.description = completedQuestion.jsonText.description
          Object.assign(target, completedQuestion);

          for (let [key, value] of Object.entries(target)) {
            setFormValues((prev) => ({
              ...prev,
              [key]: value
            }));
          }
          const options = generateOptionsFromJson(completedQuestion.jsonText, completedQuestion.type);
          console.log(options, "da");
          setAnswerList(options.answerList)
          setCriteriaList(options.criteriaList);
          refreshSteps('type', completedQuestion.type);
          console.log(options)
        } else {
          setErrorMessage(
            'Hubo un error al consultar los datos de la pregunta, intente de nuevo mas tarde'
          );
          setOpenModalError(true);
        }
      }
    };
    const getCatalogs = async() =>{
      const categories = await trackPromise(getQUESTION_CATEGORY()) as Array<IGeneralParameter>;
      setCategory(categories.map((cat:any)=>{
        return {
          value: cat.parameter_code,
          label: cat.name
        }
      }));
      const customFieldsResult = await trackPromise(getCustomFields()) as Response;
      if (customFieldsResult.status === 200) {
        let customFields = (await customFieldsResult.json()).updateFields;
        setCustomFields(customFields)
      }  
    }
    getCompleteQuestion();
    getCatalogs();
  }, []);

  useEffect(() => {
    if (formValues.user === '') {
      const userName = userContext.username;
      if (userName && userName.length > 0) {
        setFormValues((prev) => ({
          ...prev,
          user: userName
        }));
      }
    }
    if (formValues.active) {
      setFormValues((prev) => ({
        ...prev,
        state: 'Publicada'
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModalSuccess = () => {
    setOpenModalSuccess(false);
    if (backActionOverride ) {
      backActionOverride();
    } else {
      setAux(true);
    }

  };


  const saveQuestion = async () => {
    const questionToSend = {
      "title": formValues.title,
      "type": formValues.type,
      "updateField": formValues.updateField,
      "updateSystem": formValues.updateSystem,
      //"userId": userContext.userId, Check how to get the real user id this is getting de document number
      "userId": 156540,
      "category": formValues.category,
      "jsonText": jsonText || {},
      "active": true,
      "id": formValues.id || undefined
    }

    const questionResponse: Response = await trackPromise(action === "Edit" ?
      updateQuestion(questionToSend) : createQuestion(questionToSend)) as Response;
      
    if (questionResponse.status === 200) {
      const question: IQuestion = new IQuestion((await questionResponse.json()).question);
      setSuccessMessage(`La pregunta se ha ${action === "Edit" ? "editado" : "creado"} con éxito`);
      setOpenModalSuccess(true);
      if (afterUpdateAction) {
        afterUpdateAction(question)
      }
    } else {
      setErrorMessage('Hubo un error, intente de nuevo mas tarde');
      setOpenModalError(true);
    }

  }


  const handleCloseModalError = () => {
    setOpenModalError(false);
    if (action === "Edit") {
      backActionOverride();
    } else {
      setAux(true);
    }
  };

  const refreshSteps = (name: string, value: number) => {
    if (name === 'type' && TYPES[TYPES.findIndex(type => type.value === value)].answers) {
      setSteps(['Definir Pregunta', 'Agregar respuestas', 'Previsualizar pregunta']);
      setIncrementStep(1);
    } else if (name === 'type') {
      setSteps(['Definir Pregunta', 'Previsualizar pregunta']);
      setIncrementStep(2);
    }
  }


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if(name==="updateSystem"){
      setFormValues((prev) => ({
        ...prev,
        [name]: e.target.checked
      }));
      if(e.target.checked){
        setFormValues((prev) => ({
          ...prev,
          updateField: customFields[0].id
        }));
      }
    }else{
      setFormValues((prev) => ({
        ...prev,
        [name]: value
      }));
    }
    
    const error = Validations(name, value, formValidations) || '';
    setFormErrors((prev) => ({
      ...prev,
      [name]: error
    }));
    refreshSteps(name, value);

  };


  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + incrementStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - incrementStep);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));


  return (
    <>
      {!aux ? (
        <>
          <Grid container item xs={12} lg={2} md={12} >
            <div className="custom-stepper">
              {!isSmallScreen ? <>
                <Grid container item xs={12} className="title-container">
                  <Typography variant="h3" className="title">
                    {action === "Edit" ? "Editar" : "Agregar"} Pregunta
                  </Typography>
                </Grid>
                <Divider />
              </> : null}
              <Stepper activeStep={activeStep} orientation={isSmallScreen ? "horizontal" : "vertical"} style={{ "background": "none" }} >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>

                      <Typography>
                        {label}
                      </Typography>

                    </StepLabel>

                  </Step>
                ))}
              </Stepper>
            </div>
          </Grid>
          <Grid container item xs={12} lg={10} md={12} className="question-form">
            {activeStep === 0 ? (
              <Grid container item xs={12} className="question-container2">
                <Grid container item xs={12} className="title-container">
                  <Typography variant="h2" className="title">
                    Definir Pregunta
                  </Typography>
                </Grid>
                <Grid container item xs={12} direction="row" className="question-content2">
                  <Grid item xs={12} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">
                      Tipo de pregunta*
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-select-type"
                      select
                      name="type"
                      value={formValues.type || ''}
                      onChange={handleChange}
                      error={!!formErrors.type}
                      helperText={formErrors.type}
                      InputLabelProps={{ shrink: false }}
                      margin="none"
                      variant="outlined"
                    >
                      {TYPES.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">
                      Categoria*
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-select-type"
                      select
                      name="category"
                      value={formValues.category || ''}
                      onChange={handleChange}
                      error={!!formErrors.category}
                      helperText={formErrors.category}
                      InputLabelProps={{ shrink: false }}
                      margin="none"
                      variant="outlined"
                    >
                      {category.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">
                      Enunciado*
                    </Typography>
                    <TextField
                      fullWidth
                      name="title"
                      placeholder="Enunciado "
                      multiline
                      rows={1}
                      variant="outlined"
                      value={formValues.title || ''}
                      onChange={handleChange}
                      error={!!formErrors.title}
                      helperText={formErrors.title}
                      margin="none"
                    />
                  </Grid>

                  <Grid item xs={12} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">
                      Descripción
                    </Typography>
                    <TextField
                      fullWidth
                      name="description"
                      placeholder="Descripción "
                      multiline
                      rows={5}
                      variant="outlined"
                      value={formValues.description || ''}
                      onChange={handleChange}
                      error={!!formErrors.description}
                      helperText={formErrors.description}
                      margin="none"
                    />
                  </Grid>


                  <Grid item xs={4} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">
                      Actualiza Sistema
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues.updateSystem}
                          onChange={handleChange}
                          name="updateSystem"
                          color="primary"
                        />
                      }
                      label="Actualiza Sistema?"
                    />
                  </Grid>
                  
                  {formValues.updateSystem?<Grid item xs={8} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">
                      Seleccione un campo relacionado
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-select-type"
                      select
                      name="updateField"
                      value={formValues.updateField || ''}
                      onChange={handleChange}
                      error={!!formErrors.updateField}
                      helperText={formErrors.updateField}
                      InputLabelProps={{ shrink: false }}
                      margin="none"
                      variant="outlined"
                    >
                      {customFields.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>:null}
                </Grid>
              </Grid>
            ) : null}
            {(activeStep === 1) ? (

              ![6, 7].includes(formValues.type) ?

                <Grid container item xs={12} className="question-container2">
                  <Grid container item xs={12} className="title-container">
                    <Typography variant="h2" className="title">
                      Agregar Respuestas
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} direction="row" className="question-content2">

                    <Grid container>
                      <Typography variant="h5" className="question-title">Defina el enunciado de la respuesta y luego haga clic en el botón “Agregar respuesta” Puede agregar un máximo de 10 (diez) opciones de respuesta si el tipo de pregunta lo permite.</Typography>
                    </Grid>

                    <AnswerForm setAnswerList={setAnswerList} answerList={answerList} labelButton={"respuesta"} labelText={"Enunciado de la respuesta"} questionType={formValues.type} />
                  </Grid>
                </Grid>
                :
                <Grid container item xs={12} className="question-container2">
                  <Grid container item xs={12} className="title-container">
                    <Typography variant="h2" className="title">
                      Agregar Respuestas
                    </Typography>
                  </Grid>
                  <Grid className="question-content2">
                    <Typography variant="h5" className="question-title">Defina el enunciado de la respuesta/criterios y luego haga clic en el botón “Agregar respuesta/criterio” Puede agregar un máximo de 10 (diez) opciones si el tipo de pregunta lo permite.</Typography>
                  </Grid>

                  <AnswerForm setAnswerList={setCriteriaList} answerList={criteriaList} labelButton={"criterio"} labelText={"Enunciado del criterio"} questionType={formValues.type} />



                  <AnswerForm setAnswerList={setAnswerList} answerList={answerList} labelButton={"respuesta"} labelText={"Enunciado de la respuesta"} questionType={formValues.type} />
                </Grid>

            ) : null}
            {activeStep === 2 ? (
              <Grid container item xs={12} className="question-container2">
                <Grid container item xs={12} className="title-container">
                  <Typography variant="h2" className="title">
                    Previsualizar Pregunta
                  </Typography>
                </Grid>
                <Grid container item xs={12} direction="row" className="question-content2">
                  <SurveyComponent setJsonText={setJsonText} question={formValues} answerList={answerList} criteriaList={criteriaList} />
                </Grid>
              </Grid>
            ) : null}


          </Grid>
          <Grid container item xs={12} lg={2} md={12}>

          </Grid>
          <Grid container item xs={12} lg={10} md={12} className="buttons-container-question">
            <Grid container item xs={12} md={6} className="left-buttons">
              {activeStep === 0 ? (

                <>
                  {backActionOverride ? <Button
                    startIcon={<ChevronLeftIcon />}
                    className="cancel-button"
                    variant="contained"
                    size="small"
                    onClick={backActionOverride}
                  >
                    Cancelar
                  </Button> :
                    <Button
                      startIcon={<ChevronLeftIcon />}
                      className="cancel-button"
                      variant="contained"
                      size="small"
                      component={Link}
                      to="/admin/dashboard/banco-preguntas"
                    >
                      Cancelar
                    </Button>}
                </>
              ) : (
                <Button
                  startIcon={<ArrowBackIcon />}
                  className="cancel-button"
                  variant="contained"
                  size="small"
                  onClick={handleBack}
                >
                  Anterior
                </Button>
              )}
            </Grid>
            <Grid container item xs={12} md={6} className="right-buttons">
              {activeStep >= steps.length - 1 ? (
                <Button
                  disabled={!isValid}
                  onClick={saveQuestion}
                  className="save-button"
                  variant="contained"
                  size="small"
                >
                  Guardar
                </Button>
              ) : (

                <Button
                  variant="contained"
                  className="save-button"
                  onClick={handleNext}
                  size="small"
                  disabled={(!isValid && activeStep === 0) || (isInvalidAnswer() && activeStep === 1)}
                >
                  Siguiente
                </Button>
              )}
            </Grid>
          </Grid>


          <SuccessModal
            successMsg={successMessage}
            handleCloseModal={handleCloseModalSuccess}
            openModalSuccess={openModalSuccess}
          />
          <ErrorModal
            errorMsg={errorMessage}
            handleCloseModal={handleCloseModalError}
            openModalError={openModalError}
          />
          {/* <LoadingLockPage load={initLoad}/> */}
          <LoadingLockPage load={promiseInProgress} />

        </>
      ) : (
        <Redirect to="/admin/dashboard/banco-preguntas" />
      )}
    </>
  );
};

export default QuestionForm;


