import { IQuestion } from "./IQuestion";

export class ISection {
  id: number;
  title: string;
  description: string;
  surveyId: number;
  order: number;
  questions: IQuestion[] = [];

  constructor(def: any) {
    this.id = def.id || null;
    this.order = def.order || null;
    this.title = def.title || "";
    this.surveyId = def.surveyId || null;
    this.description = def.description || "";
    this.questions = def.questions || [];
  }

}