import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import 'moment/locale/es';
import esLocale from "date-fns/locale/es";
import { trackPromise } from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link, Redirect } from 'react-router-dom';
import Validations from '../../../helpers/Validations';
import DateFnsUtils from '@date-io/date-fns';
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import UserContext from '../../../utils/UserContext';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { createSurvey, updateSurvey } from '../../../services/SurveysService';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import './SurveyForm.scss';
import moment from 'moment';
import { useMediaQuery, useTheme } from '@material-ui/core';

const initialFormValues = {
  title: "",
  description: "",
  startDate: "",
  endDate: ""
}

const formValidations = {
  title: {
    error: "",
    validate: "textNumber",
    maxLength: 300,
    minLength: 10,
    required: true
  },
  description: {
    error: "",
    validate: "textNumber",
    maxLength: 500,
    minLength: 0,
    required: false
  },
  startDate: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    required: true
  },
  endDate: {
    error: "",
    validate: "normalDate",
    minDate: new Date(),
    required: true
  },
}

interface SurveyFormProps {
  action?: string;
  surveySelected?: IGetSurveys;
  valid?: any;
  onUpdate?:any
}

const SurveyForm: React.FC<SurveyFormProps> = ({ action, surveySelected, valid, onUpdate }) => {
  const userContext = React.useContext(UserContext);
  const { promiseInProgress } = usePromiseTracker();
  const [formValues, setFormValues] = useState(new IGetSurveys());
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [aux, setAux] = useState<boolean>(false);

  const isValid =
    formValues.title.length > 0 &&
    formValues.startDate != null &&
    formValues.endDate != null &&
    !formErrors.title &&
    !formErrors.description &&
    !formErrors.startDate &&
    !formErrors.endDate


  useEffect(() => {
    if (formValues.status === "") {
      setFormValues(prev => ({
        ...prev,
        state: "Publicada"
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (surveySelected) {
      const formData = async () => {
        const target: IGetSurveys = new IGetSurveys();
        Object.assign(target, surveySelected)
        for (let [key, value] of Object.entries(target)) {
          setFormValues(prev => ({
            ...prev,
            [key]: value
          }));
        }
      }
      formData();
      if (valid != null) {
        valid(isValid);
      }
    }
  }, [action, surveySelected])

  const handleCloseModalSuccess = () => {
    setOpenModalSuccess(false);
    setAux(true);
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
    window.location.reload();
  };

  const changeRecognitionDate = (dateValue: Date | null, nameField: string) => {
    const value = dateValue ? dateValue?.toString() : '';
    setFormValues(prev => ({
      ...prev,
      [nameField]: value
    }));
    const error: string = Validations(nameField, value, formValidations) || "";
    setFormErrors((prev: any) => ({
      ...prev,
      [nameField]: error
    }));
    if (valid != null) {
      valid(isValid);
    }
    if(onUpdate){
      onUpdate((prev: any) => ({
        ...prev,
        [nameField]: value
      }));
    }

  }

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
    const error = Validations(name, value, formValidations) || "";
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
    if (valid != null) {
      valid(isValid);
    }
    if(onUpdate){
      onUpdate((prev: any) => ({
        ...prev,
        [name]: value
      }));
    }
  }

  const requestSurveys = async () => {

    const surveyToSend = {
      "title": formValues.title,
      "description": formValues.description,
      "startDate": formValues.startDate,
      "endDate": formValues.endDate
    }

    if (action === "Edit") {
      const responseCOF: Response = await trackPromise(updateSurvey(surveyToSend));
      if (responseCOF.status === 200) {
        setSuccessMessage("La encuesta se ha modificado con exito");
        setOpenModalSuccess(true);
        setAux(true);

      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    } else {
      const responseCOF: Response = await trackPromise(createSurvey(surveyToSend));
      if (responseCOF.status === 200) {
        const result = await responseCOF.json();
        setSuccessMessage("La encuesta se ha agregado de manera exitosa");
        setOpenModalSuccess(true);
      } else {
        setErrorMessage("Hubo un error, intente de nuevo mas tarde");
        setOpenModalError(true);
      }
    }
  }

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));


  return (
    <>
      {!aux ?
        <>
          <Grid container item xs={12} className={action !== "Edit" ? "surveys-form" : ""}>

            <Grid container item xs={12} className={action !== "Edit" ? "surveys-container2" : ""}>
              {action !== "Edit" &&
                <Grid container item xs={12} className="title-container">
                  <Typography variant="h2" className="title" >
                    Nueva encuesta
                  </Typography>
                </Grid>
              }
              <Grid container item xs={12} direction="row" className="surveys-content2">
                <Grid item xs={12} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Título de la encuesta*</Typography>
                  <TextField
                    fullWidth
                    name="title"
                    placeholder="Título "
                    multiline
                    rows={1}
                    variant="outlined"
                    value={formValues.title || ""}
                    onChange={handleChange}
                    error={!!formErrors.title}
                    helperText={formErrors.title}
                    margin="none"
                  />
                </Grid>

                <Grid item xs={12} className="field-box-margin">
                  <Typography variant="h5" className="field-box-title">Descripción</Typography>
                  <TextField
                    fullWidth
                    name="description"
                    placeholder="Descripción "
                    multiline
                    rows={5}
                    variant="outlined"
                    value={formValues.description || ""}
                    onChange={handleChange}
                    error={!!formErrors.description}
                    helperText={formErrors.description}
                    margin="none"
                  />
                </Grid>

                <Grid container item xs={12} spacing={isSmallScreen ? 0 : 6} >
                  <Grid item xs={isSmallScreen ? 12 : 6} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">Fecha de inicio de la encuesta*</Typography>
                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        openTo="year"
                        views={['year', 'month', 'date']}
                        value={formValues.startDate || null}
                        onChange={(value) => changeRecognitionDate(value, 'startDate')}
                        minDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            disabled
                            {...params}
                            fullWidth
                            name="startDate"
                            margin="none"
                            label={formValues.startDate !== null ? "" : "Seleccione inicio"}
                            error={!!formErrors.startDate}
                            helperText={formErrors.startDate}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined" />
                        )} />
                    </LocalizationProvider>
                  </Grid>


                  <Grid item xs={isSmallScreen ? 12 : 6} className="field-box-margin">
                    <Typography variant="h5" className="field-box-title">Fecha de finalización de la encuesta*</Typography>
                    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        disabled={!!formErrors.startDate ? true : !moment(formValues.startDate).isValid() ? true : false}
                        openTo="year"
                        views={['year', 'month', 'date']}
                        value={formValues.endDate || null}
                        onChange={(value) => changeRecognitionDate(value, 'endDate')}
                        minDate={formValues.startDate == null ? new Date() : formValues.startDate}
                        renderInput={(params) => (
                          <TextField
                            disabled
                            {...params}
                            fullWidth
                            name="endDate"
                            margin="none"
                            label={formValues.endDate !== null ? "" : "Seleccione fin"}
                            error={!!formErrors.endDate}
                            helperText={formErrors.endDate}
                            InputLabelProps={{ shrink: false }}
                            variant="outlined" />
                        )} />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

          {action !== "Edit" &&
            <Grid container item xs={12} className="buttons-container-survey">
              <Grid container item xs={12} md={action === "Edit" ? 7 : 6} className="left-buttons">
                <Button startIcon={<ChevronLeftIcon />} className="cancel-button" variant="contained" size="small" component={Link} to="/admin/dashboard/encuestas-pro">Cancelar</Button>
              </Grid>
              <Grid container item xs={12} md={action === "Edit" ? 5 : 6} className="right-buttons">
                <Button disabled={!isValid} onClick={requestSurveys} className="save-button" variant="contained" size="small">Agregar encuesta</Button>
              </Grid>
            </Grid>
          }

          <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
          <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
          {/* <LoadingLockPage load={initLoad}/> */}
          <LoadingLockPage load={promiseInProgress} />
        </>
        :
        <Redirect to="/admin/dashboard/encuestas-pro" />
      }
    </>
  )
}

export default SurveyForm
