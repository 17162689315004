import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import './HeaderForm.scss';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import SurveyForm from '../SurveyForm';
import { IFile } from '../../../interfaces/IFile';
import IconButton from '@material-ui/core/IconButton';
import { uploadFileSignedUrl } from '../../../services/s3Service';
import { updateSurvey } from '../../../services/SurveyServices';
import { InputAdornment, MenuItem, TextField, useMediaQuery, useTheme } from '@material-ui/core';
import Validations from '../../../helpers/Validations';

const initialFormValues = {
  startBannerUrl: '',
  endBannerUrl: '',
  user: '',
  username: '',
  startBanner: '',
  endBanner: '',
  status: ''
};

const formValidations = {
  startBannerUrl: {
    error: '',
    validate: 'website',
    maxLength: 500,
    minLength: 0,
    required: false
  },
  status: {
    error: '',
    validate: 'textNumber',
    minLength: 1,
    required: true
  },
  endBannerUrl: {
    error: '',
    validate: 'website',
    maxLength: 500,
    minLength: 0,
    required: false
  },
  user: {
    error: '',
    validate: 'text',
    maxLength: 20,
    minLength: 5,
    required: false
  },
  username: {
    error: '',
    validate: 'text',
    maxLength: 50,
    minLength: 5,
    required: false
  }
};

interface SurveyFormProps {
  surveyInfo: IGetSurveys;
  setErrorMessage: any
  setOpenModalError: any
  setSuccessMessage: any
  setOpenModalSuccess: any
}

const HeaderForm: React.FC<SurveyFormProps> = ({ surveyInfo, setErrorMessage, setOpenModalError,   setSuccessMessage, setOpenModalSuccess }) => {

  const [headerFiles, setHeaderFiles] = useState<Array<IFile>>([]);
  const [footerfiles, setFooterfiles] = useState<Array<IFile>>([]);
  const [surveyHeader, setSurveyHeader] = useState<IGetSurveys>(new IGetSurveys());
  const [formErrors, setFormErrors] = useState(initialFormValues);
  const [basicIsValid, setBasicIsValid] = useState<boolean>(false);

  const allIsValid =
    !formErrors.user &&
    !formErrors.username &&
    !formErrors.endBannerUrl &&
    !formErrors.startBannerUrl;

  const status = [
    {
      value: 'Publicada',
      label: 'Publicada'
    },
    {
      value: 'Sin publicar',
      label: 'Sin publicar'
    }
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const formats: string[] = ['image/png', 'image/jpeg', 'image/jpg'];

  useEffect(() => {
    window.scrollTo(0, 0);

    const loadHeaderInfo = async () => {
      setSurveyHeader(surveyInfo);

      if (surveyInfo.startBanner) {
        setHeaderFiles((prev: any) => [...prev, { previewUrl: surveyInfo.startBanner }]);
        setSurveyHeader((prev) => ({
          ...prev,
          ...{ fileTypeStartBanner: (surveyInfo.startBanner || '').split('.').pop() }
        }));
      }
      if (surveyInfo.endBanner) {
        setFooterfiles((prev: any) => [...prev, { previewUrl: surveyInfo.endBanner }]);
        setSurveyHeader((prev) => ({
          ...prev,
          ...{ fileTypeEndBanner: (surveyInfo.endBanner || '').split('.').pop() }
        }));
      }
      if (!surveyInfo.status) {
        setSurveyHeader((prev) => ({
          ...prev,
          ...{ status: 'Activo' }
        }));
      }
    };
    loadHeaderInfo();
  }, []);

  const updateHeader = async () => {
    try{
      const result: Response = await trackPromise(updateSurvey(surveyHeader)) as Response;
      const resultSaveSurvey = await result.json();
      if (headerFiles[0].file && resultSaveSurvey.singnedStartBannerUr) {
        await trackPromise(
          uploadFileSignedUrl(resultSaveSurvey.singnedStartBannerUr, headerFiles[0].file)
        );
      }
      if (footerfiles[0].file && resultSaveSurvey.signedEndBannerUr) {
        await trackPromise(
          uploadFileSignedUrl(resultSaveSurvey.signedEndBannerUr, footerfiles[0].file)
        );
      }
      setSuccessMessage(
        'Se ha actualizado el encabezado de la encuesta con éxito'
      );
      setOpenModalSuccess(true);
    }catch(e){
      setErrorMessage(
        'No ha sido posible actualizar el encabezado de la cuenta. Por favor intente más tarde'
      );
      setOpenModalError(true);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const img = new Image();


    if (name === 'headerFiles') {
      if (headerFiles.length < 1 && e.target.files.length > 0) {
        if (formats.includes(e.target.files[0].type)) {
          img.src = URL.createObjectURL(e.target.files[0]);

          if (e.target.files[0].size > 1 * 1000 * 1024) {
            setErrorMessage(
              'La imagen excede las dimensiones de espacio 1MB autorizados'
            );
            setOpenModalError(true);
            return;
          }

          img.onload = () => {
            console.log(img.height,  img.width)
            if (img.height !== 200 || img.width !== 1024) {
              setErrorMessage(
                'La imagen excede las dimensiones permitidas'
              );
              setOpenModalError(true);
              return;
            }

            setSurveyHeader((surveyHeader) => ({
              ...surveyHeader,
              ...{ fileTypeStartBanner: e.target.files[0].type.split('/')[1] }
            }));
            setHeaderFiles((prev: any) => [
              ...prev,
              { file: e.target.files[0], previewUrl: URL.createObjectURL(e.target.files[0]) }
            ]);

          };

        }
      } else {
        if (e.target.files.length > 0) {
          console.error(
            'No puede subir mas archivos, si desea cambiar o eliminar la imagen principal, utilice el botón eliminar ubicado en la parte superior izquierda de la imagen y cargue de nuevo otra imagen'
          );
        }
      }
    } else if (name === 'footerFiles') {
      if (footerfiles.length < 1 && e.target.files.length > 0) {
        if (formats.includes(e.target.files[0].type)) {
          img.src = URL.createObjectURL(e.target.files[0]);

          if (e.target.files[0].size > 1 * 1000 * 1024) {
            setErrorMessage(
              'La imagen excede las dimensiones de espacio 1MB autorizados'
            );
            setOpenModalError(true);
            return;
          }

          img.onload = () => {
            if ((img.height > 400 && img.height < 200) || (img.width > 1024 && img.width < 800)) {
              setErrorMessage(
                'La imagen excede las dimensiones permitidas'
              );
              setOpenModalError(true);
              return;
            }
            setSurveyHeader((surveyHeader) => ({
              ...surveyHeader,
              ...{ fileTypeEndBanner: e.target.files[0].type.split('/')[1] }
            }));
            setFooterfiles((prev: any) => [
              ...prev,
              { file: e.target.files[0], previewUrl: URL.createObjectURL(e.target.files[0]) }
            ]);
          }
        }

      } else {
        if (e.target.files.length > 0) {
          console.error(
            'No puede subir mas archivos, si desea cambiar o eliminar la imagen principal, utilice el botón eliminar ubicado en la parte superior izquierda de la imagen y cargue de nuevo otra imagen'
          );
        }
      }
    } else {
      setSurveyHeader((prev) => ({
        ...prev,
        [name]: value
      }));
      const error = Validations(name, value, formValidations) || '';
      setFormErrors((prev) => ({
        ...prev,
        [name]: error
      }));
    }
  };

  const handleDeleteFile = (fileToDelete: IFile, section: string) => {
    if (section === 'header') {
      setHeaderFiles([]);
    }

    if (section === 'footer') {
      setFooterfiles([]);
    }
  };

  return (
    <Grid className="setting-form">
      <Grid container item xs={12} className="setting-container2">
        <SurveyForm
          action="Edit"
          surveySelected={surveyHeader}
          valid={setBasicIsValid}
          onUpdate={setSurveyHeader}
        />

        <Grid container item xs={12} direction="row" className="surveys-content2">
          <Grid container className="line" />

          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Banner de bienvenida
            </Typography>
            <Typography variant="h5" className="setting-title">
              Para adjuntar un banner de clic en el botón "Adjuntar archivo" y seleccione la
              imagen que desea adjuntar. La imagen debe estar en formato jpg, debe tener
              dimensiones de 200 pixeles de alto y 1024 pixeles de ancho, y tener un peso máximo de
              1 Mb.
            </Typography>
          </Grid>

          <Grid container>
            {headerFiles.length > 0 &&
              headerFiles.map((file: IFile, index) => (
                <Grid item xs={12} key={index} className="card-image-container">
                  <div className="card-header-container">
                    <Typography variant="h5" className="regular-serif card-title-item">
                      {file.file?.name}
                    </Typography>
                    <IconButton
                      style={{ padding: 0, height: '30px' }}
                      onClick={() => handleDeleteFile(file, 'header')}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <Grid container item xs={12} justify="flex-end" className="card-image-content">
                    <img src={file.previewUrl} alt="banner de inicio" className="image-noticia" />
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid container item xs={12} justify="flex-start">
            <div className="upload-button-container">
              <Button
                disabled={headerFiles.length > 0}
                variant="contained"
                component="label"
                className="upload-button"
              >
                <Typography variant="h5" className="regular-serif">
                  Adjuntar archivo
                </Typography>
                <div>
                  <input
                    type="file"
                    name="headerFiles"
                    onChange={handleChange}
                    accept="image/png, image/jpeg, image/jpg"
                    hidden
                  />
                </div>
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Enlace del banner de inicio
            </Typography>
            <TextField
              fullWidth
              name="startBannerUrl"
              placeholder="Enlace del banner de inicio"
              multiline
              rows={1}
              variant="outlined"
              value={surveyHeader.startBannerUrl || ''}
              onChange={handleChange}
              error={!!formErrors.startBannerUrl}
              helperText={formErrors.startBannerUrl}
              margin="none"
            />
          </Grid>

          <Grid container className="line" />

          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Banner de finalización
            </Typography>
            <Typography variant="h5" className="setting-title">
              Para adjuntar un banner de clic en el botón "Adjuntar archivo" y seleccione la
              imagen que desea adjuntar. La imagen debe estar en formato jpg, debe tener
              dimensiones entre 200 a 400 pixeles de ancho y 800 a 1024 pixeles de alto, y tener un peso máximo de
              1 Mb.
            </Typography>
          </Grid>
          <Grid container>
            {footerfiles.length > 0 &&
              footerfiles.map((file: IFile, index) => (
                <Grid item xs={12} key={index} className="card-image-container">
                  <div className="card-header-container">
                    <Typography variant="h5" className="regular-serif card-title-item">
                      {file.file?.name}
                    </Typography>
                    <IconButton
                      style={{ padding: 0, height: '30px' }}
                      onClick={() => handleDeleteFile(file, 'footer')}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                  <Grid container item xs={12} justify="flex-end" className="card-image-content">
                    <img
                      src={file.previewUrl}
                      alt="banner finalizacion"
                      className="image-noticia"
                    />
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid container item xs={12} justify="flex-start">
            <div className="upload-button-container">
              <Button
                disabled={footerfiles.length > 0}
                variant="contained"
                component="label"
                className="upload-button"
              >
                <Typography variant="h5" className="regular-serif">
                  Adjuntar archivo
                </Typography>
                <div>
                  <input
                    type="file"
                    name="footerFiles"
                    onChange={handleChange}
                    accept="image/png, image/jpeg, image/jpg"
                    hidden
                  />
                </div>
              </Button>
            </div>
          </Grid>

          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Enlace del banner de finalización
            </Typography>
            <TextField
              fullWidth
              name="endBannerUrl"
              placeholder="Enlace del banner de finalización"
              multiline
              rows={1}
              variant="outlined"
              value={surveyHeader.endBannerUrl || ''}
              onChange={handleChange}
              error={!!formErrors.endBannerUrl}
              helperText={formErrors.endBannerUrl}
              margin="none"
            />
          </Grid>
          <Grid container className="line" />

          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Estado
            </Typography>
            <TextField
              fullWidth
              id="outlined-select-type"
              select
              name="status"
              value={surveyHeader.status || ''}
              onChange={handleChange}
              InputLabelProps={{ shrink: false }}
              margin="none"
              variant="outlined"
              error={!!formErrors.status}
              helperText={formErrors.status}
            >
              {status.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid container className="line" />
          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Datos del Solicitante
            </Typography>
          </Grid>
          <Grid container item xs={12} spacing={isSmallScreen ? 0 : 6}>
            <Grid item xs={isSmallScreen ? 12 : 6} className="field-box-margin">
              <Typography variant="h5" className="bold-serif-secondTitle">
                Nombre Completo:
              </Typography>
              <TextField
                fullWidth
                name="username"
                placeholder="Nombre Completo"
                variant="outlined"
                value={surveyHeader.username || ''}
                onChange={handleChange}
                error={!!formErrors.username}
                helperText={formErrors.username}
                margin="none"
              />
            </Grid>

            <Grid item xs={isSmallScreen ? 12 : 6} className="field-box-margin">
              <Typography variant="h5" className="bold-serif-secondTitle">
                Username sistema:
              </Typography>
              <TextField
                fullWidth
                name="user"
                placeholder="Username sistema"
                variant="outlined"
                value={surveyHeader.user || ''}
                onChange={handleChange}
                error={!!formErrors.user}
                helperText={formErrors.user}
                margin="none"
              />
            </Grid>
          </Grid>
          <Grid container className="line" />
          <Grid item xs={12} className="field-box-margin">
            <Typography variant="h5" className="field-box-title">
              Enlace de la encuesta
            </Typography>
            <TextField
              fullWidth
              name="surveyUrl"
              placeholder="Enlace de la encuesta"
              disabled={true}
              variant="outlined"
              value={surveyHeader.surveyUrl || ''}
              onChange={handleChange}
              margin="none"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LinkIcon color="primary" />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12} className="buttons-setting-survey">
        <Grid container item xs={12} md={6} className="left-buttons">
          <Button
            startIcon={<ChevronLeftIcon />}
            className="cancel-button"
            variant="contained"
            size="small"
            component={Link}
            to="/admin/dashboard/encuestas-pro"
          >
            Cancelar
          </Button>
        </Grid>
        <Grid container item xs={12} md={6} className="right-buttons">
          <Button
            disabled={!allIsValid || !basicIsValid}
            className="save-button"
            variant="contained"
            onClick={updateHeader}
            size="small"
          >
            Actualizar encuesta
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeaderForm;
