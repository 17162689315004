import React, { useState, useEffect } from 'react';
import UserContext from '../../../../utils/UserContext';


import './QuestionFormPage.scss';
import { useParams } from 'react-router';
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import Grid from '@material-ui/core/Grid';
import { permissionAdminCreateSurvey } from '../../../../utils/PermissionsVariables';
import { containsAny } from '../../../../helpers/sharedFunctions';
import QuestionForm from '../../../../components/Survey/QuestionForm';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';

const QuestionFormPage: React.FC = () => {
    let params: any = useParams();
    const userContext = React.useContext(UserContext);
    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [action, setAction] = useState<string>();
    


    useEffect(() => {
        window.scrollTo(0, 0);
        const urlPath = window.location.href;
        if (urlPath.includes('crear')) {
            setAction("Create")
        } else if (urlPath.includes('editar')) {
            setAction("Edit")
        }
        setInitLoad(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <Grid container item xs={12} className="back-offers-list-section">
                <ButtonBackListOffers url="/admin/dashboard/banco-preguntas" text="Regresar al listado de preguntas" />
            </Grid>

            <Grid container className="main-container">
                <Grid container item xs={12} className="question-container">



                    <Grid container item xs={12} md={9} lg={9} className="line" />
                    <Grid container >
                        {action === "Create" ?
                            <>
                                {containsAny(permissionAdminCreateSurvey, userContext.userPermissions) ?
                                    <QuestionForm
                                        action="Create"
                                    />
                                    :
                                    <NotAuthorized />
                                }
                            </>
                            :null
                        }
                    </Grid>
                </Grid>
            </Grid>
            <LoadingLockPage load={initLoad}/>
        </>
    )


}

export default QuestionFormPage 
