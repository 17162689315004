import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import { updateSurvey } from '../../../services/SurveysService';
import ModalOffersAction from '../../LaboralOffers/ModalOffersAction';
import WarningIcon from "@material-ui/icons/Warning";
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import './SurveyList.scss';
import UserContext from '../../../utils/UserContext';
import { permissionAdminSurveys } from '../../../utils/PermissionsVariables';
import { containsAny } from '../../../helpers/sharedFunctions';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface SurveyListProps{
    surveys: Array<IGetSurveys>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage:any;
    handleUpdate: any;
    exportAnswers: any;
}

const SurveyList: React.FC<SurveyListProps> = ({surveys, pageSize, onChanhePageSize,  initPage, setInitPage, handleUpdate, exportAnswers}) => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [surveySelected, setSurveySelected] = useState<IGetSurveys>(new IGetSurveys());
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
    const [openChangeModal, setOpenChangeModal] = useState<boolean>(false);
    const [openChangeModal2, setOpenChangeModal2] = useState<boolean>(false);
    const [idSurveySelected, setIdSurveySelected] = useState<number | null>(null);

    const columnsAnnouncement: GridColDef[] = [
        { field: 'title', headerName: 'Título', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Título'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              return(
                  <span className="name-cell" id="shortDescription-cell">{params.value}</span>
                )
            }
        },        
        { field: 'description', headerName: 'Descripción', width: 400, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Descripción'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              return(
                  <span className="name-cell" id="shortDescription-cell">{params.value}</span>
                )
            }
        },
        { field: 'createdAt', headerName: 'Fecha de creación', width: 200, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Fecha de creación'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              const valueDate = moment((params.value)?.toString()).format('YYYY/MM/DD');
              return(
                  <span className="date-cell">{valueDate}</span>
                )
            }
        },
        { field: 'status', headerName: 'Estado', width: 150, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <strong>
                {params.value === 'Publicada' && <div className="active-state state-button-cell">Publicada</div>}
                {params.value === 'Finalizada' && <div className="inactive-state state-button-cell">Finalizada</div>}
                {params.value === 'Sin publicar' && <div className="inactive-state state-button-cell">Sin publicar</div>}
            </strong>
        )
        },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >

                    {containsAny(permissionAdminSurveys,userContext.userPermissions) &&
                        <MenuItem  onClick={(e)=>{handleCloseMenu(); handleUpdate('Settings', surveySelected) }}>
                            Configurar encuesta
                        </MenuItem>
                    }
                    {
                        <MenuItem  onClick={(e)=>{handleCloseMenu(); handleChangeStateInactive(e, surveySelected.id)}}>
                            Desactivar encuesta
                        </MenuItem>
                    }
                    {
                        surveySelected.status !== "Finalizada" ?
                        <MenuItem  onClick={(e)=>{handleCloseMenu(); exportAnswers(surveySelected.id)}}>
                            Exportar respuestas
                        </MenuItem>
                        :
                        <MenuItem>
                        </MenuItem>
                    }
                </Menu>
            </>
        ) },
    ];

    const handleClick = (survey: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(survey.currentTarget);
        const surveyObj: IGetSurveys = Object.assign(params.row);
        setSurveySelected(surveyObj);
    };

    const closeModalState = ()=>{
        setOpenChangeModal(false);
    }

    const closeModalState2 = ()=>{
        setOpenChangeModal2(false);
    }

    const handleChangeStateInactive = (e: any, idSurvey: number)=>{
        setIdSurveySelected(idSurvey);
        setOpenChangeModal2(true);
    }

    const changeStateInactive = async()=>{
        if(surveySelected.id){
            setOpenChangeModal2(false);
            const responseCSF = await trackPromise(updateSurvey({id: idSurveySelected, state: "Inactivo", user: surveySelected.user}));
            if(responseCSF.status === 200){
            const result = await responseCSF.json();
            setSuccessMessage(result.message)
            setOpenChangeModal2(false);
            setOpenModalSuccess(true);
            setOpenModalError(false);
          } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
          }
        }
    }

    const changeStateFinalized = async()=>{
        if(surveySelected.id){
            setOpenChangeModal(false);
            const responseCSF = await trackPromise(updateSurvey({id: idSurveySelected, state: "Finalizada", user: surveySelected.user}));
            if(responseCSF.status === 200){
                const result = await responseCSF.json();
                setSuccessMessage(result.message)
                setOpenChangeModal(false);
                setOpenModalSuccess(true);
                setOpenModalError(false);
            } else {
                setErrorMessage("Hubo un error, intente de nuevo mas tarde");
                setOpenModalError(true);
                setOpenModalSuccess(false);
            }
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
        window.location.reload();
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };


    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setInitPage(1)
    }

    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid rowHeight={100} autoHeight className="data-grid-list" disableColumnMenu rows={surveys} columns={columnsAnnouncement} pageSize={pageSize} pagination  density="compact" disableSelectionOnClick  onPageChange={(params:GridPageChangeParams)=>handlePage(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />
                <ModalOffersAction
                    open={openChangeModal}
                    handleClose={closeModalState}
                    handleContinue={changeStateFinalized}
                    iconModal={<WarningIcon className="big-warning-icon" />}
                    title="¿Está seguro de cambiar el estado a Finalizado?"
                    headerdescription="Esta intentando cambiar el estado de la encuesta a Finalizado"
                    description=""
                    backBtnText="Regresar"
                    colorBtnBack= ""
                    continueBtnText="Cambiar"
                    colorBtnContinue="#f44336"
                    valid={true}
                />
                <ModalOffersAction
                    open={openChangeModal2}
                    handleClose={closeModalState2}
                    handleContinue={changeStateInactive}
                    iconModal={<WarningIcon className="big-warning-icon" />}
                    title="¿Está seguro de eliminar la encuesta?"
                    headerdescription="Esta intentando eliminar una encuesta"
                    description=""
                    backBtnText="Regresar"
                    colorBtnBack= ""
                    continueBtnText="Eliminar"
                    colorBtnContinue="#f44336"
                    valid={true}
                />
                <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                <LoadingLockPage load={promiseInProgress}/>
            </Grid>
        </Grid>
    );
}

const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map(item=>(
                            <MenuItem value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default SurveyList;