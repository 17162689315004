import React, {useState, useEffect } from 'react';
import jwt from 'jsonwebtoken';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { isLogin } from '../services/AuthService';
import UserContext from '../utils/UserContext';
import { getListPermissions, getListPermissionsByRole } from '../services/AdminServices';
import LoadingLockPage from '../components/ShareComponents/LoadingLockPage';

const PrivateRoute = ({component: Component, roleRoute, path, layout: Layout, ...rest}) => {
    const [initLoad, setInitLoad] = useState(true);
    const userContext = React.useContext(UserContext);
    const [ready, setReady]= useState(false);
    const [canAccess, setCanAccess] = useState(false);
    const location = useLocation();
    
    const { pathname } = location;
    const alloweRedirectLocations = ["/egresado/encuesta/"]
    const [redirectionPath, setRedirectionPath] = useState("/")

    useEffect(()=>{

        if(!isLogin() && alloweRedirectLocations.filter(item=>{ return pathname.includes(item)}).length>0){
            sessionStorage.setItem('pendingToRedirect', pathname);
            setRedirectionPath("/login");
        } 
        setReady(false);
        
        const getData = async() => {
            if(userContext.permissions.length === 0 && userContext.permissionsRoles.length === 0){
                const repsonsePermissions = await getListPermissions();
                if(repsonsePermissions.status === 200){
                    const resultPermissions = (await repsonsePermissions.json()).permissionsInfo;
                    userContext.setPermissions(resultPermissions)
                    const resPermissionsRoles = await getListPermissionsByRole();
                    if(resPermissionsRoles.status === 200){
                        const resultPermissionsRoles = (await resPermissionsRoles.json()).permissionsByRole;
                        userContext.setPermissionsRoles(resultPermissionsRoles);
                        getDataUser(resultPermissionsRoles);
                    }
                }
            } else {
                getDataUser(userContext.permissionsRoles);
            }
        }
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path]);

    const getDataUser = (permissionsRolesArray) =>{
        let temporaryRole = userContext.roleSelected > 0 ? userContext.roleSelected : 0;
        let permissionsObj;
        if(sessionStorage.getItem('Bearer')){
            const itemProfile = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
            if(jwt.decode(itemProfile)){
                const resDecoded = jwt.decode(itemProfile);
                if(resDecoded){
                    const profileDecoded = resDecoded.message;
                    const getRoles = profileDecoded.roles;
                    if(sessionStorage.getItem('temp')){
                        const itemTemp = sessionStorage.getItem('temp') ? sessionStorage.getItem('temp')+"" : "";
                        if(jwt.decode(itemTemp)){
                            const tempDecoded = jwt.decode(itemTemp);
                            if(tempDecoded.roleSelected){
                                if(roleRoute.includes(tempDecoded.roleSelected)){
                                    permissionsObj = permissionsRolesArray.find(item=>item.id === tempDecoded.roleSelected);
                                    setCanAccess(true);
                                    temporaryRole = tempDecoded.roleSelected || 0;
                                } else{
                                    const resultAccess = getRoles.some(function(role) {
                                        if(roleRoute.includes(role)){
                                            const temp = jwt.sign({ roleSelected: role, temp: userContext.temporaryKey || false}, '1234@#');
                                            sessionStorage.setItem('temp', temp)
                                            permissionsObj = permissionsRolesArray.find(item=>item.id === role);
                                            temporaryRole = role;
                                        }
                                        return roleRoute.includes(role);
                                    });
                                    if(!resultAccess){
                                        permissionsObj = userContext.rolePermissions.permissions.length>0 ? userContext.rolePermissions : undefined;
                                    }
                                    setCanAccess(resultAccess);
                                }
                            }
                            userContext.setTemporaryKey(tempDecoded.temp || false);
                        }
                    }
                    userContext.setUsername(profileDecoded.username !== '' ? profileDecoded.username : '');
                    userContext.setUserId(profileDecoded.documentId !== '' ? profileDecoded.documentId : '');
                    userContext.setName(profileDecoded.name !== '' ? profileDecoded.name : '');
                    userContext.setRolesUser(getRoles);
                    userContext.setRoleSelected(temporaryRole);
                    userContext.setRolePermissions(permissionsObj);
                    userContext.setUserPermissions(permissionsObj.permissions.map(x=>x.id));
                }
            }
        } else {
            userContext.setUsername('');
            userContext.setUserId('');
            userContext.setName('');
            userContext.setRolesUser([]);
            userContext.setRolePermissions(undefined);
            userContext.setUserPermissions([]);
        }
        setReady(true);
        setInitLoad(false);
    }


    return (
        <>
            {
                ready &&
                <Route {...rest} render={props => (
                    isLogin() && canAccess ?
                    <Layout><Component {...props} /></Layout>
                    : <Redirect to={redirectionPath}/>
                )} />
            }
            <LoadingLockPage load={initLoad} />
        </>
    );
};

export default PrivateRoute;
