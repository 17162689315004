import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface SettingOptionBarProps {
  selected: string;
  surveySelectedId?: number;
  loadInfo: any;
}

const SettingOptionBar: React.FC<SettingOptionBarProps> = ({ surveySelectedId, selected, loadInfo}) => {


  const buttons = [
    { id: 1, name: 'Encabezado de la encuesta', state: 'header', url: `/admin/dashboard/configurar-encuesta/${surveySelectedId}/header/`},
    { id: 2, name: 'Configurar preguntas', state: 'questions', url: `/admin/dashboard/configurar-encuesta/${surveySelectedId}/questions/`},
    { id: 3, name: 'Previsualizar', state: 'preview', url: `/admin/dashboard/configurar-encuesta/${surveySelectedId}/preview/`}
  ]

  return (

    <Grid item xs={12} className="state-option-section">
      <Grid item xs={12} className="state-option-container">
        <Grid container item xs={12} className="container-buttons">
          {buttons.map(item => (
            <Link  onClick={loadInfo} key={item.id} to={item.url} className="link-state-bar">
              <div className={selected == item.state ? 'button-definition-active' : 'button-definition'}>
                <Typography variant="h5" className="regular-serif">
                  {item.name}
                </Typography>
              </div>
            </Link>
          ))}
        </Grid>
      </Grid>


    </Grid>

  )
}

export default SettingOptionBar;
