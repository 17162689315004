import React, { useState } from 'react';

import 'moment/locale/es';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import ArrowCircleDownIcon from '@material-ui/icons/ArrowDownwardOutlined';
import ArrowCircleUpIcon from '@material-ui/icons/ArrowUpwardOutlined';
import IconButton from '@material-ui/core/IconButton';

import './AnswerForm.scss';
import 'survey-core/defaultV2.css';


import {
  Button,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { IAnswer } from '../../../interfaces/IAnswer';

interface AnswerFormProps {
  setAnswerList: any;
  answerList: IAnswer[];
  labelButton: string;
  labelText: string;
  questionType: number
}

const AnswerForm: React.FC<AnswerFormProps> = ({ setAnswerList, answerList, labelButton, labelText, questionType }) => {

  const [answerValue, setAnswerValue] = useState<string>('');
  const [answer, setAnswer] = useState<IAnswer>(new IAnswer({}));
  const [addAnswer, setAddAnswer] = useState<boolean>(true);


  const handleAddAnswer = () => {
    if (answerValue !== "" && answerList.length <= 9) {
      setAnswerList((answerList: IAnswer[]) => [...answerList, {
        id: answerList.length,
        order: answerList.length + 1,
        title: answerValue
      }]);

      setAnswerValue("");
    }

  };


  const updateAnswer = (answerToUpdate: IAnswer) => {

    const updatedArray = answerList.map((answer) => {
      if (answerToUpdate.id === answer.id) {
        answerToUpdate.title = answerValue;
        return answerToUpdate;
      }

      return answer;
    });

    setAnswerList(updatedArray);
    setAddAnswer(true);
    setAnswer(new IAnswer({}))
    setAnswerValue("");

  };

  const handleEditAnswer = (answerToUpdate: IAnswer) => {
    setAnswerValue(answerToUpdate.title);
    setAnswer(answerToUpdate);
    setAddAnswer(false);
  };

  const handleDelAnswer = (answerToDelete: IAnswer) => {
    const newAnswerList = answerList.filter((answer) => {
      return answer.id !== answerToDelete.id;
    });

    setAnswerList(newAnswerList);
  };



  const handlePosition = (old_index: number, new_index: number) => {

    if (Number(new_index) >= 0 && !(new_index > answerList.length - 1)) {
      if (new_index >= answerList.length) {
        let k = new_index - answerList.length + 1;
        let undefined;
        while (k--) {
          answerList.push(new IAnswer({}));
        }
      }
      answerList.splice(new_index, 0, answerList.splice(old_index, 1)[0]);
    }
    const newAnswerList: IAnswer[] = answerList.map(answer => {
      if (answer.id === answerList[new_index].id) {
        return { ...answer, order: answerList[old_index].order };
      }
      if (answer.id === answerList[old_index].id) {
        return { ...answer, order: answerList[new_index].order };
      }
      return answer;
    });

    console.log(newAnswerList);

    setAnswerList([...newAnswerList]);

  };


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles((theme) => ({
    delete: {
      borderRadius: 5,
      border: '2px solid',
      borderColor: "#FF4E15",
    },
    delete_text: {
      color: "#FF4E15"
    },
    edit: {
      borderRadius: 5,
      border: '2px solid',
      borderColor: "#004b73",
    },
    edit_text: {
      color: "#004b73"
    }


  }));
  const classes = useStyles();

  return (
    <>
      <Grid container item xs={isSmallScreen ? 12 : (![6, 7].includes(questionType) ? 12 : 6)} direction="row" className="question-content2">

        <Grid item xs={12} className="field-box-margin">
          <Typography variant="h5" className="field-box-title">
            {labelText}*
          </Typography>
          <TextField
            fullWidth
            name="answer"
            placeholder={labelText}
            multiline
            rows={3}
            variant="outlined"
            margin="none"
            inputProps={{ maxLength: 500 }}
            onChange={(e) => {
              setAnswerValue(e.target.value);
            }}

            value={answerValue}
          />

          <Grid container item className="btn-container">
            <Button style={{ display: addAnswer ? undefined : 'none' }} onClick={handleAddAnswer} variant="contained" className="create-button" endIcon={<AddIcon />} >
              <Typography variant="h6" className="regular-serif">Agregar {labelButton}</Typography>
            </Button>

            <Button style={{ display: !addAnswer ? undefined : 'none' }} onClick={() => updateAnswer(answer)} variant="contained" className="create-button" endIcon={<AddIcon />} >
              <Typography variant="h6" className="regular-serif">Actualizar {labelButton}</Typography>
            </Button>

          </Grid>

          {answerList.map((answer, index) => (

            <Grid container spacing={1} key={index}>
              <Grid container item xs={12} className="line" />
              <Grid item xs={12}>
                <Typography variant="h5" className="field-box-text">{answer.order + "." + answer.title}</Typography>
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 8} sm container spacing={1} className="option-answer-container">
                <Grid container item xs={isSmallScreen ? 6 : 4} className="option-position">


                  <Grid spacing={1} container item>
                    {answer.order < answerList.length && <Grid item>
                      <IconButton className="border_icon" size={isSmallScreen ? "small" : "medium"} style={{ display: (answer.order < answerList.length) ? undefined : 'none' }}
                        onClick={() => handlePosition(index, index + 1)}>
                        <ArrowCircleDownIcon fontSize="inherit" />
                      </IconButton >
                    </Grid>}
                    {answer.order > 1 && <Grid item>
                      <IconButton className="border_icon" size={isSmallScreen ? "small" : "medium"} style={{ display: (answer.order > 1) ? undefined : 'none' }}
                        onClick={() => handlePosition(index, index - 1)}>
                        <ArrowCircleUpIcon fontSize="inherit" />
                      </IconButton >
                    </Grid>}
                  </Grid>
                </Grid>

                <Grid container item xs={isSmallScreen ? 6 : 8} spacing={1} className="option-answer-container">
                  <Grid item>
                    <Button style={{ display: !isSmallScreen ? undefined : 'none' }} onClick={() => handleEditAnswer(answer)} variant="outlined"
                      className={classes.edit} startIcon={<EditIcon className={classes.edit_text} />}>
                      <Typography variant="h6" className={classes.edit_text} color="primary">
                        Editar
                      </Typography>
                    </Button>
                    <IconButton size="small" style={{ display: isSmallScreen ? undefined : 'none' }} onClick={() => handleEditAnswer(answer)}
                      className={classes.edit} >
                      <EditIcon fontSize="inherit" className={classes.edit_text} />
                    </IconButton>

                  </Grid>
                  <Grid item>
                    <Button style={{ display: !isSmallScreen ? undefined : 'none' }} onClick={() => handleDelAnswer(answer)} variant="outlined"
                      className={classes.delete} startIcon={<DeleteIcon className={classes.delete_text} />}>
                      <Typography variant="h6" className={classes.delete_text} >
                        Eliminar
                      </Typography>
                    </Button>
                    <IconButton size="small" style={{ display: isSmallScreen ? undefined : 'none' }} onClick={() => handleDelAnswer(answer)}
                      className={classes.edit} >
                      <DeleteIcon fontSize="inherit" className={classes.delete_text} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default AnswerForm;
