import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import { DataGrid, GridColDef, GridOverlay, GridColParams, GridCellParams, GridPageChangeParams, GridBaseComponentProps } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import SuccessModal from '../../ShareComponents/SuccessModal';
import ErrorModal from '../../ShareComponents/ErrorModal';
import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import './QuestionList.scss';
import UserContext from '../../../utils/UserContext';
import { permissionAdminSurveys } from '../../../utils/PermissionsVariables';
import { containsAny } from '../../../helpers/sharedFunctions';
import { IGetQuestions } from '../../../interfaces/IGetQuestions';
import { updateQuestion } from '../../../services/QuestionService';
import QuestionForm from '../QuestionForm';
import ResponsiveBasicModal from '../../ResponsiveBasicModal';
import { IQuestion } from '../../../interfaces/IQuestion';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      padding: '5px 6px 10px 12px',
      height: '30px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  }),
)(InputBase);

interface QuestionListProps{
    questions: Array<IGetQuestions>;
    pageSize: number;
    onChanhePageSize: any;
    initPage: number;
    setInitPage:any;
    updateList: any
}

const QuestionList: React.FC<QuestionListProps> = ({questions, pageSize, onChanhePageSize,  initPage, setInitPage, updateList}) => {
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [questionSelected, setQuestionSelected] = useState<IGetQuestions>(new IGetQuestions());
    const [errorMessage, setErrorMessage]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [successMessage, setSuccessMessage]= useState('');
    const [openModalSuccess, setOpenModalSuccess] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);

    const columnsAnnouncement: GridColDef[] = [
        { field: 'id', headerName: 'Código', width: 250, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Código'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => {
              return(
              <span className="name-cell" >{params.value}</span>
        )} },
        { field: 'title', headerName: 'Enunciado', width: 400, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Enunciado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              return(
                  <span className="name-cell" id="shortDescription-cell">{params.value}</span>
                )
            }
        },
        { field: 'type', headerName: 'Tipo Pregunta', width: 300, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Tipo Pregunta'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              return(
                  <span className="name-cell">{params.value}</span>
                )
            }
        },
        { field: 'createdAt', headerName: 'Fecha de creación', width: 200, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Fecha de creación'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) =>{
              const valueDate = moment((params.value)?.toString()).format('YYYY/MM/DD');
              return(
                  <span className="date-cell">{valueDate}</span>
                )
            }
        },
        { field: 'active', headerName: 'Estado', width: 150, renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                    {'Estado'}
                </span>
                <UnfoldMoreIcon fontSize="small" className="color-arrows"/>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <FormGroup>
                <FormControlLabel
                control={
                    <Switch
                    checked={params.value as boolean}
                    onChange={(e)=>{handleChangeStatus(e, params)}}
                    color="primary"
                    />
                }
                label={params.value?"Activa":"Inactiva"}
                />
            </FormGroup>  
        )
        },
        { field: 'Acción', headerName: 'Acciones', width: 100, sortable: false ,renderHeader: (params: GridColParams) => (
            <strong className="column-states">
                <span className="column-header-title">
                {'Acciones'}
                </span>
            </strong>
          ), renderCell:(params: GridCellParams) => (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=>handleClick(e,params)}>
                    <SettingsIcon/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                >
                    {containsAny(permissionAdminSurveys,userContext.userPermissions) &&
                        <MenuItem  onClick={(e)=>{handleCloseMenu(); handleClickOpen(); } }>
                            Editar
                        </MenuItem>
                    }
                </Menu>
            </>
        ) },
    ];

    const handleClick = (question: React.MouseEvent<HTMLButtonElement>, params:GridCellParams) => {
        setAnchorEl(question.currentTarget);
        const questionObj: IGetQuestions = Object.assign(params.row);
        setQuestionSelected(questionObj);
    };

    const handleClickOpen = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const handleChangeStatus = async(event:any, params:GridCellParams)=>{
        const questionToChange: IGetQuestions = Object.assign(params.row);
        console.log(event.target.checked, questionToChange);
        if(questionToChange){
            const responseCSF = await trackPromise(updateQuestion({id: questionToChange.id, active: event.target.checked}));
            if(responseCSF.status === 200){
            updateActiveInList({...questionToChange, active: !questionToChange.active});
            setSuccessMessage(`La pregunta se ha ${event.target.checked?"activado": "inactivado"} con éxito`)
            setOpenModalSuccess(true);
            setOpenModalError(false);
            
          } else {
            setErrorMessage("Hubo un error, intente de nuevo mas tarde");
            setOpenModalError(true);
            setOpenModalSuccess(false);
          }
        }
    }

    const handleCloseModalSuccess = ()=>{
        setOpenModalSuccess(false);
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handlePage = (pageParams: GridPageChangeParams)=>{
        setInitPage(1)
    }

    const updateTitleInList = (question:any) =>{
        updateList(questions.map(q => {
            if (q.id === question.id) {
                return { ...q, title: question.title};
            }
            return q;
        }))
    };


    const updateActiveInList = (question:any) =>{
        console.log(questions);
        updateList(questions.map(q => {
            if (q.id === question.id) {
                console.log(question);
                return { ...q, active: question.active};
            }
            return q;
        }))
    };


    return (
        <Grid container className="data-grid-container">
            <Grid item xs={12}>
                <DataGrid rowHeight={100} autoHeight className="data-grid-list" disableColumnMenu rows={questions} columns={columnsAnnouncement} pageSize={pageSize} pagination  density="compact" disableSelectionOnClick  onPageChange={(params:GridPageChangeParams)=>handlePage(params)}
                components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        ColumnSortedAscendingIcon: customSortAsc,
                        ColumnSortedDescendingIcon: customSortDesc,
                        Footer:(props)=>CustomFooter(props, initPage, onChanhePageSize, pageSize)
                }}
                />       
                <ResponsiveBasicModal
                    open={openEdit}
                    handleClose={handleCloseEdit}
                    children={<QuestionForm questionSelected={IQuestion.fromGetQuestons(questionSelected)} action="Edit" backActionOverride={handleCloseEdit} afterUpdateAction={updateTitleInList} ></QuestionForm>}
                />         
                <SuccessModal successMsg={successMessage} handleCloseModal={handleCloseModalSuccess} openModalSuccess={openModalSuccess} />
                <ErrorModal errorMsg={errorMessage} handleCloseModal={handleCloseModalError} openModalError={openModalError} />
                <LoadingLockPage load={promiseInProgress}/>
            </Grid>
        </Grid>
    );
}




const CustomFooter = (props: GridBaseComponentProps, initPage:number, onChanhePageSize?: any, valueRows?: number)=> {
    const { state, api } = props;
    const rowsPerPageOptions = [5,10,25,50,100];

    useEffect(()=>{
        if(initPage === 0){
            api.current.setPage(0)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initPage]);

    return (
        <>
            <div className="footer-container">
                <div className="rows-number-container">
                    <span className="rows-number-text">Mostrar</span>
                    <Select
                        id="demo-simple-select"
                        value={valueRows}
                        onChange={onChanhePageSize}
                        input={<BootstrapInput/>}
                    >
                        {rowsPerPageOptions.map((item, index)=>(
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))}
                    </Select>
                    <span className="rows-number-text">Registros</span>
                </div>
                <Pagination
                    className="pagination-container"
                    color="primary"
                    page={state.pagination.page+1}
                    count={state.pagination.pageCount}
                    onChange={(event, value) =>api.current.setPage(value-1)}
                    showFirstButton
                    showLastButton
                />
            </div>
        </>
    );
}

const CustomNoRowsOverlay = () => {
    return (
        <GridOverlay className="not-result-list">
            <GpsNotFixedIcon fontSize="large"/>
            <div className="not-results-text">Sin registros</div>
        </GridOverlay>
    );
}

const customSortAsc = (props: GridBaseComponentProps)=><KeyboardArrowUpIcon fontSize="small"/>
const customSortDesc = (props: GridBaseComponentProps)=><KeyboardArrowDownIcon fontSize="small"/>

export default QuestionList;