import { ISection } from "./ISection";

export class IGetSurveys {
  id: number = 0;
  status: string = '';
  title: string = '';
  description: string = '';
  surveyUrl: string = '';
  user?: string = '';
  startDate?: Date | null = null;
  endDate?: Date | null = null;
  startBannerUrl? = '';
  startBanner? = '';
  endBannerUrl? = '';
  endBanner? = '';
  fileTypeStartBanner?='';
  fileTypeEndBanner?='';
  createdAt?: string = '';
  updatedAt?: string = '';
  username?: string = '';
  Sections?: ISection[] = []; 
  constructor(def?: any) {
    if (def) {
      this.id = def.id || null;
      this.status = def.status || '';
      this.title = def.title || '';
      this.description = def.description || '';
      this.surveyUrl = def.surveyUrl || '';
      this.user = def.user || '';
      this.username = def.username;
      this.startDate = def.startDate || '';
      this.endDate = def.endDate || '';
      this.startBannerUrl = def.startBannerUrl || '';
      this.startBanner = def.startBanner || '';
      this.endBannerUrl = def.endBannerUrl || '';
      this.endBanner = def.endBanner || '';
      this.createdAt = def.createdAt || '';
      this.updatedAt = def.updatedAt || '';
      this.fileTypeEndBanner = def.fileTypeEndBanner || '';
      this.fileTypeStartBanner = def.fileTypeStartBanner || '';
      this.Sections = def.Sections;
    }
  }
}
