import { IListParameter, IParameter } from "../interfaces/IParameter";

const baseUrl = process.env.REACT_APP_SIE_APP_PROXY_BASE_URL;
const TOKEN_KEY = "Bearer";

// Formulario de registro del egresado
export const getLABORAL_SECTOR = async () => {
    return await getParameter('/catalog/parameters/LABORAL_SECTOR');
}

export const getETHNIC_GROUP = async () => {
    return await getParameter('/catalog/parameters/ETHNIC_GROUP');
}

// tipo de documento
export const getID_TYPE  = async () => {
    return await getParameter('/catalog/parameters/ID_TYPE');
}

export const getMARITAL_STATUS = async () => {
    return await getParameter('/catalog/parameters/MARITAL_STATUS');
}

export const getCOUNTRIES = async () => {
    return await getParameter('/catalog/parameters/COUNTRIES');
}

export const getEMPLOYMENT_SITUATION = async () => {
    return await getParameter('/catalog/parameters/EMPLOYMENT_SITUATION');
}

export const getCOMPANY_TYPE = async () => {
    return await getParameter('/catalog/parameters/COMPANY_TYPE');
}

// Motivos de rechazo al cambiar el estado
export const getREJECTION_GRADUATE = async () => {
    return await getParameter('/catalog/parameters/STATE_OBSERVATIONS');
}

// Motivos de rechazo para empresas
export const getREJECTION_COMPANY = async () => {
    return await getParameter('/catalog/parameters/REJECTION_COMPANY');
}
// Niveles Academicos
export const getACADEMIC_LEVEL = async () => {
    return await getParameter('/catalog/parameters/ACADEMIC_LEVEL');
}
// Departamentos
export const getDEPARTAMENT = async () => {
    return await getParameter('/catalog/parameters/DEPARTAMENT');
}
// Ciudades
export const getCITY= async () => {
    return await getParameter('/catalog/parameters/CITY');
}
// AREAS
export const getAREA= async () => {
    return await getParameter('/catalog/parameters/AREA');
}
// SUBAREAS
export const getSubArea= async () => {
    return await getParameter('/catalog/parameters/SUBAREA');
}
// COMPETENCIAS
export const getCOMPETENCE= async () => {
    return await getParameter('/catalog/parameters/COMPETENCE');
}
// IDIOMAS O LENGUAJE
export const getLANGUAGE= async () => {
    return await getParameter('/catalog/parameters/LANGUAGE');
}
// NIVEL DE LENGUAJE
export const getLANGUAGELEVEL= async () => {
    return await getParameter('/catalog/parameters/LANGUAGE_LEVEL');
}
// OCUPACIONES
export const getOCCUPATION= async () => {
    return await getParameter('/catalog/parameters/OCCUPATIONS_NEW');
}
// TIPO DE OCUPACIONES
export const getOccupationType= async () => {
    return await getParameter('/catalog/parameters/OCCUPATION_TYPE');
}
// TIPO DE CONTATO
export const getTypeOfContract= async () => {
    return await getParameter('/catalog/parameters/TYPE_OF_CONTRACT');
}

// Causales de finalizacion de oferta
export const getFinishedObservation= async () => {
    return await getParameter('/catalog/parameters/OBSERVATION_FINISHED');
}

// categorias
export const getCATEGORY = async () => {
    return await getParameter('/catalog/parameters/PUBLISHING_CATEGORY');
}

//countries-cities
export const getCOUNTRIES_CITIES = async () => {
    return await getListParameters('/catalog/parameters-listG/COUNTRIES/CITY');
}
// estamentos
export const getEventEstate = async () => {
    return await getParameter('/catalog/parameters/EVENT_ESTATE');
}

// discapacidades
export const getDISABILITIES = async () => {
    return await getParameter('/catalog/parameters/DISABILITIES');
}

// tipos de asociacion
export const getTYPE_OF_ASSOCIATION = async () => {
    return await getParameter('/catalog/parameters/TYPE_OF_ASSOCIATION');
}

// asociaciones
export const getASSOCIATION = async () => {
    return await getParameter('/catalog/parameters/ASSOCIATION');
}

// nombre programa especial
export const getNameSpecialAdmission = async () => {
    return await getParameter('/catalog/parameters/NAME_SPECIAL_ADMISSION_PROGRAM');
}

// tipo programa PAES
export const getTypeAdmissionPAES = async () => {
    return await getParameter('/catalog/parameters/PAES_PROGRAM');
}

// tipo de distinciones
export const getDISTINCTIONS_CATEGORIES = async () => {
    return await getParameter('/catalog/parameters/DISTINCTIONS_CATEGORIES');
}

// tipos de vinculacion
export const getEMPLOYMENT_RELATIONSHIP = async () => {
    return await getParameter('/catalog/parameters/EMPLOYMENT_RELATIONSHIP');
}

// areas de desempeño
export const getPERFORMANCE_AREA = async () => {
    return await getParameter('/catalog/parameters/PERFORMANCE_AREA');
}

// afinidad labor
export const getEMPLOYMENT_AND_PROFESSION_RELATIONSHIP= async () => {
    return await getParameter('/catalog/parameters/EMPLOYMENT_AND_PROFESSION_RELATIONSHIP');
}

// tipo de reconocimiento
export const getTYPEOFRECOGNITION= async () => {
    return await getParameter('/catalog/parameters/TYPE_OF_RECOGNITION');
}

// instituciones
export const getINSTITUTIONS= async () => {
    return await getParameter('/catalog/parameters/INSTITUTIONS');
}

// estado emprendimiento
export const getVENTURE_STATE= async () => {
    return await getParameter('/catalog/parameters/VENTURE_STATE');
}

// news parameters
export const getCAMPUS_PROGRAMS = async () => {
    return await getListParameters('/catalog/parameters-listG/CAMPUS/PROGRAMS');
}
export const getCAMPUS_FACULTIES = async () => {
    return await getListParameters('/catalog/parameters-list/CAMPUS/FACULTIES');
}
export const getFACULTIES_PROGRAMS = async () => {
    return await getListParameters('/catalog/parameters-list/FACULTIES/PROGRAMS');
}
export const getCAMPUS= async () => {
    return await getParameter('/catalog/parameters/CAMPUS');
}
export const getFACULTIES= async () => {
    return await getParameter('/catalog/parameters/FACULTIES');
}
export const getPROGRAMS= async () => {
    return await getParameter('/catalog/parameters/PROGRAMS');
}

export const getENTITY_TYPE = async () => {
    return await getParameter('/catalog/parameters/ENTITY_TYPE');
}

export const getINSTITUTION = async () => {
    return await getParameter('/catalog/parameters/INSTITUTION');
}

export const getGROUP_CLASSIFICATION = async () => {
    return await getParameter('/catalog/parameters/GROUP_CLASSIFICATION');
}

export const getSALARY_ASPIRATION = async () => {
    return await getParameter('/catalog/parameters/SALARY_ASPIRATION');
}

export const getEDUCATION_STATE = async () => {
    return await getParameter('/catalog/parameters/EDUCATION_STATE');
}

export const getTYPE_PERSON = async () => {
    return await getParameter('/catalog/parameters/TYPE_PERSON');
}

export const getTYPE_STUDY = async () => {
    return await getParameter('/catalog/parameters/TYPE_STUDY');
}

export const getTYPE_EVENT = async () => {
    return await getParameter('/catalog/parameters/TYPE_EVENT');
}

export const getTYPE_EVENT_USER = async () => {
    return await getParameter('/catalog/parameters/TYPE_EVENT_USER');
}
export const getACADEMIC_LEVEL_NEW = async () => {
    return await getParameter('/catalog/parameters/ACADEMIC_LEVEL_NEW');
}
export const getQUESTION_CATEGORY = async () => {
    return await getParameter('/catalog/parameters/QUESTION_CATEGORY');
}
export const getQUESTION_TYPE = async () => {
    return await getParameter('/catalog/parameters/QUESTION_TYPE');
}

export const tempGROUPCLASSIFICATION=[
    {
        parameter_name: 'GROUP_CLASSIFICATION',
        parameter_code: 1,
        name: 'Clasificación 1',
        description: '',
        createdAt: "",
        updatedAt: ""
      }, {
        parameter_name: 'GROUP_CLASSIFICATION',
        parameter_code: 2,
        name: 'Clasificación 2',
        description: '',
        createdAt: '',
        updatedAt: ''
      },
]


export const getRangeMinimun= async () => {
    let token = "";
    if (sessionStorage.getItem(TOKEN_KEY)) {
    token = sessionStorage.getItem(TOKEN_KEY) ? sessionStorage.getItem(TOKEN_KEY)+"" : "";
    }
    try {
        const response = await fetch(
            `${baseUrl}/catalog/configuration/SALARY_MINIMUM`,
             { method: 'GET',
             headers:{
            'sie_token': token
            } 
            }
        );
        return response;
    } catch (error) {
        return error
    }
}


const getParameter = async(url:string)=>{
    try {
        const response = await fetch(
            `${baseUrl}${url}`,
             { method: 'GET' }
        );
        if (response.status === 200) {
            const result: IParameter = await response.json();
            return result.message;
        }
    } catch (error) {
        return error
    }
}

export const getListParameters = async (URL: string) => {
    try {
        const response = await fetch(
            `${baseUrl}${URL}`,
            {
            method: 'GET',
            }
        );

        if (response.status === 200) {
            const result:IListParameter = await response.json();
            return result;
        }
    } catch (error) {
        return error
    }
}