import React from 'react';
import Fab from '@material-ui/core/Fab';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import chatIcon from '../../../assets/images/Icono_Avatar.png';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: '1em'
  },
  tooltipText: {
    position: 'absolute',
    top: 80, // Ajusta según sea necesario
    left: -80, // Ajusta según sea necesario
    right: -60,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    zIndex: 1
  }
}));

const ChatButton = () => {
  const classes = useStyles();

  return (
    <Box
      style={{
        position: 'fixed',
        bottom: 96,
        right: 96,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <Box className={classes.tooltipText}>
        ¡Hola! Soy Amibuho, tu asistente virtual.
      </Box>
      <Tooltip title="¡Hola! Soy Amibuho, tu asistente virtual." aria-label="add" classes={{ tooltip: classes.tooltip }}>
        <Fab color="primary" aria-label="custom-icon">
          <a href='https://t.me/un_egresados_dev_bot' target='_blank' rel="noopener noreferrer">
            <img src={chatIcon} alt="custom icon" style={{ width: 96, height: 96 }} />
          </a>
        </Fab>
      </Tooltip>
    </Box>
  );
}

export default ChatButton;
