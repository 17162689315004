import React, { useState, useEffect } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
import './QuestionAdmin.scss';
import { containsAny, sortByKeyDesc } from '../../../../helpers/sharedFunctions';
import UserContext from '../../../../utils/UserContext';
import { permissionAdminSurveys } from '../../../../utils/PermissionsVariables';
import { isLogin } from '../../../../services/AuthService';
import SearchTable from '../../../../components/ManagementState/SearchTable';
import { Button, Grid, Typography } from '@material-ui/core';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { IGetQuestions } from '../../../../interfaces/IGetQuestions';
import Validations from '../../../../helpers/Validations';
import QuestionList from '../../../../components/Survey/QuestionList';
import { getQuestions } from '../../../../services/QuestionService';



const fieldsValidation = {
  searched: {
    error: "",
    validate: "",
    required: true
  }
};
const initialErrorsSearch = {
  searched: ''
}

const SurveysAdmin: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker();
  const userContext = React.useContext(UserContext);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [initPage, setInitPage] = useState<number>(0);
  const [searched, setSearched] = useState('');
  const [formErrorSearch, setFormErrorSearch] = useState(initialErrorsSearch);
  const [searchActive, setSearchActive] = useState(false);
  const [data, setData] = useState<Array<IGetQuestions>>([]);
  const [dataFiltered, setDataFiltered] = useState<Array<IGetQuestions>>([]);
  
  useEffect(() => {
    const getListQuestions = async () => {
      const getInfo: Response = await trackPromise(getQuestions());
      if (getInfo.status === 200) {
        let resultList = (await getInfo.json()).message;
        resultList = sortByKeyDesc(resultList, 'createdAt');
        setData(resultList);
        setDataFiltered(resultList);
      }
    }
    getListQuestions();
  }, [])


  const onChanhePageSize = (e: any) => {
    setRowsPerPage(e.target.value)
  }


  const filterData = (keyWord: string) => {
    let resultKeyword: Array<IGetQuestions> = [];

    if (keyWord.length > 0) {
      resultKeyword = data.filter(item => item.title.includes(keyWord));
    } else {
      resultKeyword = data;
    }
    setInitPage(0);

    setDataFiltered(resultKeyword);
  }

  

  const requestSearch = (name: string, searchVal: string) => {
    const error: string = searchVal !== '' ? Validations(name, searchVal, fieldsValidation) || "" : '';
    setFormErrorSearch(prev => ({
      ...prev,
      [name]: error
    }));
    setSearched(searchVal);
    if (searchVal !== '') {
      setSearchActive(true);
      filterData(searchVal)
    } else {
      setSearchActive(false);
      filterData('');
    }
  }

  const cancelSearch = () => {
    setSearched('')
    requestSearch('searched', '');
    setSearchActive(false)
  }

  return (
    <>
      {isLogin() ?
        <>
          {containsAny(permissionAdminSurveys, userContext.userPermissions) ?
            <>
                  <Grid container className="main-container">
                    {containsAny(permissionAdminSurveys, userContext.userPermissions) &&
                      <Grid container item xs={12} className="question-create-container">
                        <Button disabled={!containsAny(permissionAdminSurveys, userContext.userPermissions)} variant="contained" className="create-button" endIcon={<AddIcon />} component={Link} to="/admin/dashboard/crear-pregunta">
                          <Typography variant="h6" className="regular-serif">Adicionar nueva pregunta</Typography>
                        </Button>
                      </Grid>
                    }
                  </Grid>
                  <Grid container item xs={12} className="question-list-container">
                    <Grid container className="main-container">
                      <Grid container className="list-table-container">
                        <Grid container item xs={12}>
                          <Typography variant="h2" className="title-question-table-management">Preguntas</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                          <div className="header-table-container">
                            <div className="search-question-container">
                              <SearchTable textPlaceHolder="Buscar por palabra clave" title="" searched={searched} requestSearch={requestSearch} formErrorSearch={formErrorSearch} searchActive={searchActive} cancelSearch={cancelSearch} />
                            </div>
                          </div>
                        </Grid>
                        <Grid container item xs={12} className="list-container">
                          <Grid container item xs={12} className="list-view">
                            <QuestionList onChanhePageSize={onChanhePageSize} questions={dataFiltered} pageSize={rowsPerPage} initPage={initPage} setInitPage={setInitPage} updateList={setDataFiltered}  />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <LoadingLockPage load={promiseInProgress} />
            </>
            :
            <NotAuthorized />
          }
        </>
        :
        <Redirect to="/" />
      }
    </>
  )
}

export default SurveysAdmin
