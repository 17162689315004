import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useParams } from "react-router-dom";
import ButtonBackListOffers from '../../../../components/LaboralOffers/ButtonBackListOffers';
import LoadingLockPage from '../../../../components/ShareComponents/LoadingLockPage';
import SurveyForm from '../../../../components/Survey/SurveyForm';
import './SurveysFormPage.scss';
import UserContext from '../../../../utils/UserContext';
import NotAuthorized from '../../../../components/ShareComponents/NotAuthorized/NotAuthorized';
import { permissionAdminCreateSurvey, permissionAdminModifySurvey } from '../../../../utils/PermissionsVariables';
import { containsAny } from '../../../../helpers/sharedFunctions';
import SettingsForm from '../../../../components/Survey/SettingsForm';


const SurveysFormPage: React.FC = () => {
  let params: any = useParams();

  const userContext = React.useContext(UserContext);
  const [initLoad, setInitLoad] = useState<boolean>(true);
  const [action, setAction] = useState<string>("Create");
  const [surveySelectedId, setSurveySelectedId] = useState<number>();
  

  useEffect(() => {
    window.scrollTo(0, 0);
    const urlPath = window.location.href;
    if (urlPath.includes('crear')) {
      setAction("Create")
    } else if (urlPath.includes('editar')) {
      setAction("Edit");
      setSurveySelectedId(params.id);
    } else if (urlPath.includes('configurar')) {
      setAction("Settings");
      setSurveySelectedId(params.id);
    }
    setInitLoad(false);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  useEffect(() => {
    window.scrollTo(0, 0);
  })

  const handleActions = (action: string) => {
    switch (action) {
      case "Edit":
        return (
          <>
            {containsAny(permissionAdminModifySurvey, userContext.userPermissions) ?
              <Grid container className="main-container">
                <SurveyForm
                  action={action}
                />
              </Grid>
              :
              <NotAuthorized />

            }
          </>
        )
      case "Settings":
        return (
          <>
            {containsAny(permissionAdminModifySurvey, userContext.userPermissions) ?
              <SettingsForm
                action={action}
                surveySelectedId={surveySelectedId}
              />
              :
              <NotAuthorized />
            }
          </>
        )
      default:
        return (
          <>
            {containsAny(permissionAdminCreateSurvey, userContext.userPermissions) ?
              <Grid container className="main-container">

                <SurveyForm
                  action={action}
                />
              </Grid>
              :
              <NotAuthorized />
            }
          </>
        )
    }
  }


  return (
    <>
      <Grid container item xs={12} className="back-offers-list-section">
        <ButtonBackListOffers url="/admin/dashboard/encuestas-pro" text="Regresar al listado de encuestas" />
      </Grid>

      {handleActions(action)}

      <LoadingLockPage load={initLoad} />
    </>
  )
}

export default SurveysFormPage
