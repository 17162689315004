import { IPermission } from "./IPermission";
import { IPermissionRole } from "./IPermissionRole";
import { IUserPercentage } from "./IUserPercentage";
export class IUserContext{
    logged: boolean = false;
    setLogged: any;
    userId?: string = "";
    setUserId?: any;
    name?:string = "";
    setName?: any;
    username?:string = "";
    setUsername?: any;
    rolesUser: Array<number> = [];
    setRolesUser?:any;
    rolePermissions: IPermissionRole = new IPermissionRole();
    setRolePermissions?: any;
    userPermissions:Array<number> = [];
    setUserPermissions?: any;
    roleSelected:number = 0;
    setRoleSelected?: any;
    temporaryKey?: boolean;
    setTemporaryKey?:any;
    stateOffer?: string = "";
    setStateOffer?:any;
    statePostulation?: string = "";
    setStatePostulation?:any;
    stateCurriculumSection?: string = "";
    setStateCurriculumSection?:any;
    stateProfileSection?: string = "";
    setStateProfileSection?:any;    
    stateSurveysSection?: string = "";
    setStateSurveysSection?:any;
    stateSurveySection?: string = "";
    setStateSurveySection?:any;
    stateGraduateSection?: string = "";
    setStateGraduateSection?:any;
    stateCompanySection?: string = "";
    setStateCompanySection?:any;
    stateOffersSection?: string = "";
    setStateOffersSection?:any;
    roleOption?: string = "";
    setRoleOption?: any;
    idOffer?: number | null = null;
    setIdOffer?: any;
    action?: string = '';
    setAction?: any;
    permissions: Array<IPermission> = [];
    setPermissions?: any;
    permissionsRoles: Array<IPermissionRole> = [];
    setPermissionsRoles?: any;
    userPercentage?: IUserPercentage;
    handleSetUserPercentage?: any;
}