import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { IQuestion } from "../../../interfaces/IQuestion";
import { IAnswer } from "../../../interfaces/IAnswer";
import { useEffect } from "react";
import { customCss, themeJson } from "../SurveyCommon/survey-common-theme";
import '../SurveyCommon/SurveyCommon.scss';
import { RedirectionFlow } from "../../../interfaces/IQuestionSection";


interface SurveyProps {
    question?: IQuestion;
    answerList?: IAnswer[];
    criteriaList?: IAnswer[];
    setJsonText?: any;
}

export interface ReverseChoices {
    answerList:IAnswer[];
    criteriaList:IAnswer[];
}
export const generateSurveyJSON = (sections:any):any=>{
    let triggers: any[] =[];

    let json:any = {
        pages: sections.map((section: any) => {
        return {
            name: section.title,
            elements: [
            {
                name: section.title,
                type: 'panel',
                title: section.title,
                state: 'expanded',
                elements: section.SectionQuestions.map((question: any) => {
                if(question.redirect && Array.isArray(question.redirect)){
                    triggers=[...triggers, ...(question.redirect.map((flowRule:RedirectionFlow)=>{
                        if(flowRule.tagetSectionId && flowRule.tagetSectionId!==-1){
                        return  {
                            "type": "skip",
                            "expression": `{pregunta${question.questionId}} = '${flowRule.answer}'`,
                            "gotoName": `pregunta${sections.map((section:any)=> section.SectionQuestions).flat(1).find((question:any)=>question.sectionId===flowRule.tagetSectionId).questionId}`
                        }
                        }
                        return null;
                    }))];
                }  

                return {
                    ...question.Questions.jsonText,
                    ...{ isRequired: question.required, name: `pregunta${question.questionId}` }
                };
                })
            }
            ]
        };
        }),
        showTOC: true
    };

    json.triggers = triggers.filter(trigger=>trigger!=null);
    return json;
}
export const generateOptionsFromJson = (jsonText: any, selectedType: any): ReverseChoices =>{
    let typeQuestion = TYPES.find((type: any) => type.value === selectedType)?.type;
    let answerList:IAnswer[] = [];
    let criteriaList:IAnswer[] = [];
    console.log(typeQuestion)
    if (typeQuestion === "matrix") {
        answerList = jsonText.columns.map((column: { id: any; text: any; }, index: number) => ({
            id: column.id,
            title: column.text,
            order: index+1
        }));
        console.log(answerList);
        criteriaList = jsonText.rows.map((column: { id: any; text: any; }, index: number) => ({
            id: column.id,
            title: column.text,
            order: index+1
        }));

    } else if (typeQuestion === "matrixdropdown") {

        answerList = jsonText.columns.map((column: { id: any; title: any; }, index:number) => ({
            id: column.id,
            title: column.title,
            order: index+1

        }));

        criteriaList = jsonText.rows.map((column: { id: any; title: any; }, index:number) => ({
            id: column.id,
            title: column.title,
            order: index+1

        }));

    } else if (typeQuestion === "datetime-local") {
        //Nothing to do
    } else {
        console.log(jsonText.choices);
        if(jsonText.choices && jsonText.choices.length>0){
            answerList = jsonText.choices.map((choice:string, index: number) => ({
                title: choice,
                id: index+1,
                order: index+1
            }));
        }
    }

    return {
        answerList,
        criteriaList
    };    

}

export const TYPES = [
    {
      value: 1,
      label: 'Respuesta abierta corta',
      type: "text",
      answers: false,
      flow: false
    },
    {
      value: 2,
      label: 'Respuesta abierta larga',
      type: "comment",
      answers: false,
      flow: false
    },
    {
      value: 3,
      label: 'Respuesta única',
      type: "radiogroup",
      answers: true,
      flow: true
    },
    {
      value: 4,
      label: 'Respuesta múltiple',
      type: "checkbox",
      answers: true,
      flow: false
    },
    {
      value: 5,
      label: 'Respuesta lista desplegable',
      type: "dropdown",
      answers: true,
      flow: true
    },
    {
      value: 6,
      label: 'Cuadrícula de opción única',
      type: "matrix",
      answers: true,
      flow: false,
    },
    {
      value: 7,
      label: 'Cuadrícula de opción múltiple',
      type: "matrixdropdown",
      answers: true,
      flow: false
    },
    {
      value: 8,
      label: 'Carga de archivo',
      type: "file",
      answers: false,
      flow: false

    },
    {
      value: 9,
      label: 'Fecha y hora',
      type: "datetime-local",
      answers: false,
      flow: false
    }
  ];


export const generateJsonQuestion =  (question: IQuestion, answerList: IAnswer[], criteriaList: IAnswer[]): any => {

    let typeQuestion = TYPES.find((type: any) => type.value === question.type)?.type;

    let element: {} = {
        name: question.title, 
        title: question.title,
        description: question.description
    };

    if (typeQuestion === "matrix") {
        element = {
            ...element, columns: answerList
                .map((answer) => ({ id: answer.id, value: answer.id, text: answer.title }))
        };
        element = {
            ...element, rows: criteriaList
                .map((criteria) => ({ id: criteria.id, value: criteria.id, text: criteria.title }))
        };

    } else if (typeQuestion === "matrixdropdown") {
        element = {
            ...element, columns: answerList
                .map((answer) => ({
                    name: answer.title, title: answer.title, id: answer.id, cellType: "checkbox",
                    showInMultipleColumns: true, choices: [answer.title]
                }))
        };

        element = {
            ...element, rows: criteriaList
                .map((criteria) => ({
                    title: criteria.title, value: criteria.title, id: criteria.id
                }))
        };

    } else if (typeQuestion === "datetime-local") {

        typeQuestion = "text";
        element = {
            ...element, inputType: "datetime-local"
        };

    } else {
        element = {
            ...element, choices: answerList
                .sort((a, b) => a.order - b.order).map(item => item.title)
        };

    }

    element = {
        ...element, type: typeQuestion
    };

    return element;
}


const SurveyComponent: React.FC<SurveyProps> = ({ setJsonText, question, answerList, criteriaList }) => {

    const element = generateJsonQuestion(question!, answerList!, criteriaList!);
    console.log(element);
    const model: Model = new Model({ elements: [element]});

    model.css = customCss

    useEffect(() => {
      setJsonText(element);
    }, []);

    model.applyTheme(themeJson);
    return (<Survey showCompleteButton={false} model={model} />);

};


export default SurveyComponent;