import React, {useState, useEffect} from 'react';
import jwt from 'jsonwebtoken';
import Grid from "@material-ui/core/Grid";
import WarningIcon from '@material-ui/icons/Warning';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import {Link, Redirect } from 'react-router-dom';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { trackPromise} from 'react-promise-tracker';
import { usePromiseTracker } from 'react-promise-tracker';
import BeatLoader from 'react-spinners/BeatLoader';
import Validations from '../../../helpers/Validations';
import { login } from '../../../services/AuthService';
import ButtonColors from '../../../components/ShareComponents/ButtonColors';
import LoadingArea from '../../../components/ShareComponents/LoadingArea';
import { IAuth } from '../../../interfaces/IAuth';
import ErrorModal from '../../../components/ShareComponents/ErrorModal';
import UserContext from '../../../utils/UserContext';
import { IRole } from '../../../interfaces/IRole';
import { getListPermissions, getListPermissionsByRole, getlistRoles } from '../../../services/AdminServices';
import ModalOffersAction from '../../../components/LaboralOffers/ModalOffersAction';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import { containsAny, logoutAndReset } from '../../../helpers/sharedFunctions';

import './SignIn.scss';


const initialLoginValues = {
    username: "",
    password: ""
}

const initialLoginErrors = {
    username: "",
    password: "",
}

const loginValidations = {
    username: {
      error: "",
      required: true
    },
    password: {
        error: "",
        required: true,
    },
}
const SignIn:React.FC = ()=>{
    const { promiseInProgress } = usePromiseTracker();
    const userContext = React.useContext(UserContext);
    const [formValues, setFormValues] = useState(initialLoginValues);
    const [formErrors, setFormErrors] = useState(initialLoginErrors)
    const [showPass, setShowPass]= useState(false);
    const [logged, setLogged] = useState(false);
    const [roles, setRoles] = useState<Array<number>>([]);
    const [loginError, setLoginError]= useState('');
    const [serviceError, setServiceError]= useState('');
    const [openModalError, setOpenModalError] = React.useState(false);
    const [tempKey, setTempKey] = useState(true);
    const [ready,setReady] = useState(false);
    const [urlRedirect, setUrlRedirect] = useState<string>('/login');
    const [roleList, setRoleList] = useState<Array<IRole>>([]);
    const [roleListUser, setRoleListUser] = useState<Array<IRole>>([]);
    const [openModalRoles, setOpenModalRoles] = useState<boolean>(false);
    const [roleSelected, setRoleSelected] = useState<number>(0);
    const [urlRole, setUrlRole] = useState<string>("");
    const [opeModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
    const [redirectionPath, setRedirectionPath] = useState<string>("");
    useEffect(()=>{
        window.scrollTo(0, 0);
        const getInitData = async()=> {
            const responseRoleList = await getlistRoles();
            if(responseRoleList.status === 200){
                const resultList: Array<IRole> = (await responseRoleList.json()).rolesInfo;
                setRoleList(resultList);
            }
        }
        getInitData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // Handle form change
    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setLoginError('')

        // Set values
        setFormValues(prev => ({
            ...prev,
            [name]: value
        }));
        // set errors
        const error = Validations(name, value, loginValidations) || "";
        setFormErrors(prev=>({
            ...prev,
            [name]: error
        }));
    }

    const handleClickShowPassword = () => {
        setShowPass(!showPass);
    };

    const checkRedirection = () =>{
        const foundPendingRedirection =  sessionStorage.getItem('pendingToRedirect');
        if(foundPendingRedirection){
            setRedirectionPath(foundPendingRedirection)
            sessionStorage.removeItem('pendingToRedirect');
        }
        
    }
    const makeLogin = async()=>{
        setRoles([]);
        if(userContext.permissions.length === 0 && userContext.permissionsRoles.length === 0){
            const repsonsePermissions:any = await getListPermissions();
            if(repsonsePermissions.status === 200){
                const resultPermissions = (await repsonsePermissions.json()).permissionsInfo;
                userContext.setPermissions(resultPermissions)
                const resPermissionsRoles:any = await getListPermissionsByRole();
                if(resPermissionsRoles.status === 200){
                  const resultPermissionsRoles = (await resPermissionsRoles.json()).permissionsByRole;
                  userContext.setPermissionsRoles(resultPermissionsRoles);
                }
            }
        }
        const responseLogin: IAuth = await trackPromise(login(formValues));
        if(responseLogin !== undefined){
            if(responseLogin.state === true){
                if(sessionStorage.getItem('Bearer')){
                    const itemProfile:string = sessionStorage.getItem('Bearer') ? sessionStorage.getItem('Bearer')+"" : "";
                    if(jwt.decode(itemProfile)){
                        const resDecoded:any = jwt.decode(itemProfile);
                        if(resDecoded){
                            const profileDecoded = resDecoded.message;
                            const getRoles = profileDecoded.roles;
                            setRoles(getRoles);
                            if(getRoles.length >1){
                                let newRoleList: Array<IRole> = [];
                                getRoles.forEach((role: number) => {
                                    const roleElm = roleList.find(item=>item.id === role);
                                    if(roleElm){newRoleList.push(roleElm);}
                                });
                                setRoleListUser(newRoleList);
                                setOpenModalRoles(true);
                                checkRedirection();
                                setReady(true);
                                setLogged(true);
                            } else if(getRoles.length === 1) {
                                if(getRoles.includes(2) || getRoles.includes(3)){
                                    const temp = jwt.sign({ roleSelected: getRoles[0], temp: responseLogin.temporaryKey || false}, '1234@#');
                                    sessionStorage.setItem('temp', temp)
                                    if(responseLogin.timeout){
                                        setLogged(false)
                                        setRoles([])
                                        setUrlRedirect('/recuperacion-contrasena')
                                        logoutAndReset(userContext);
                                        setServiceError("Su contraseña temporal venció, para restablecer de nuevo su contraseña de clic en Aceptar")
                                        setOpenModalError(true);
                                        setReady(true);
                                    } else {
                                        setTempKey(responseLogin.temporaryKey || false);
                                        setReady(true);
                                        checkRedirection();
                                        setLogged(true);
                                    }
                                } else if (getRoles.includes(1)){
                                    const temp = jwt.sign({ roleSelected: getRoles[0], temp: responseLogin.temporaryKey || false}, '1234@#');
                                    sessionStorage.setItem('temp', temp)
                                    setReady(true);
                                    checkRedirection();
                                    setLogged(true);
                                } else {
                                    setReady(true);
                                    logoutAndReset(userContext);
                                    setLoginError('Este usuario no tiene una cuenta de egresado o empresa');
                                }
                            } else {
                                setLoginError('Se produjo una falla en el servidor, intente de nuevo mas tarde');
                                logoutAndReset(userContext);
                            }
                        }
                    }
                }
            } else{
                if(responseLogin.message !== ""){
                    setLoginError(responseLogin.message);
                } else {
                    setLoginError("");
                }
            }
        } else {
            setLogged(false);
            logoutAndReset(userContext);
            setRoles([]);
            setReady(true);
            setLoginError('Se produjo una falla en el servidor, intente de nuevo mas tarde');
        }
    };

    const handleCloseModalError = ()=>{
        setOpenModalError(false);
    };

    const closeModalRoles = ()=>{
        setOpenModalRoles(false);
        setRoleListUser([]);
        setRoleSelected(0);
        setLogged(false);
        logoutAndReset(userContext);
    };

    const changeRoleSelected = (e:any)=>{
        const {value} = e.target;
        const temp = jwt.sign({ roleSelected: value, temp: tempKey || false}, '1234@#');
        sessionStorage.setItem('temp', temp)
        setRoleSelected(value)
        let urlRoute = ""
        if(value>0){
            if(value === 1){
                urlRoute = "/egresado/dashboard";
            } else if (value === 2){
                urlRoute = "/empresa/dashboard";
            } else if (value === 3){
                if(tempKey){
                    urlRoute="/empresa/dashboard";
                } else {
                    urlRoute="/empresa/cambio-contrasena";
                }
            } else {
                urlRoute = "/admin/dashboard";
            }
        }
        setUrlRole(urlRoute)
    }

    const confirmSend = async()=>{
        setOpenModalConfirm(true);
    }
    const closeModalConfirm = () =>{
        setOpenModalConfirm(false);
    }

    const handleKeyPress = (event: any) => {
        let { name, value } = event.target;

        setFormValues(prev => ({
            ...prev,    
            [name]: value.replace(/@/g, "")
        }));
        
    }

    return(
        <>
            { !logged &&
                <Grid container component="main" className="login-container">
                    <Grid item xs={false} sm={4} md={7} className="login-container-img" />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                        <Grid item xs={12} className="login-container-box">
                            <Grid container item xs={12} direction="column" className="login-header">
                                <Typography variant="h2" className="regular-serif primaryColor">
                                    Ingreso de usuarios SIE
                                    <Grid container item xs={12} direction="row" className="subtitle">
                                        
                                        <Typography variant="h5" className="subtitle-login-info">
                                            Sistema de información programa de egresados
                                        </Typography>
                                    </Grid>
                                </Typography>
                                <Grid item xs={12}>
                                    <ButtonColors/>
                                </Grid>
                            </Grid>
                            <Grid item xs={11} className="login-container-form">
                                <form noValidate autoComplete="off">
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Usuario sin @unal.edu.co"
                                            name="username"
                                            placeholder="Ingrese su usuario"
                                            margin="normal"
                                            value={formValues.username}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={!!formErrors.username}
                                            helperText={formErrors.username}
                                            required
                                            onKeyUp={handleKeyPress}
                                            inputProps={{ style: { textTransform: "lowercase" } }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Contraseña"
                                            name="password"
                                            placeholder="Ingresa tu contraseña"
                                            margin="normal"
                                            type={showPass ? 'text' : 'password'}
                                            value={formValues.password}
                                            variant="outlined"
                                            onChange={handleChange}
                                            error={!!formErrors.password}
                                            helperText={formErrors.password}
                                            required
                                            InputProps={{
                                                endAdornment:
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        edge="end"
                                                    >
                                                        {showPass ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="h7-regular-primaryError">
                                        {loginError}
                                    </Grid>
                                    <Grid container item xs={12} justify="flex-start" direction="column">
                                        <div style={{width:'100%'}}>
                                            <LoadingArea load={promiseInProgress} loadElement={<BeatLoader size={20} margin={2} color="#14A5DC"/>} awaitingText="Espere un momento por favor"/>
                                        </div>
                                        <Button disabled={promiseInProgress} variant="contained" className="button-login" size="small" onClick={makeLogin}>
                                            <Typography variant="h6" className="regular-serif">
                                                Iniciar sesión
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </form>
                            </Grid>
                            <Grid container item xs={12} className="login-container-terms">
                                <HelpOutlineIcon className="policies-icon"/>
                                <Link className="policies-link" to="/recuperacion-contrasena">
                                    <Typography variant="h6" className="regular-serif">
                                        ¿Olvidó su contraseña? Haga clic aquí
                                    </Typography>
                                </Link>
                            </Grid>
                            <Grid item xs={11}>
                                <Button variant="contained" className="button-contact" onClick={confirmSend}>
                                    <Typography variant="h5" className="regular-serif">
                                        Sí tiene problemas para iniciar sesión contáctenos
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <ModalOffersAction
                        open={opeModalConfirm}
                        handleClose={closeModalConfirm}
                        iconModal={<InfoIcon className="big-info-icon" />}
                        title="Señor usuario(a):"
                        headerdescription=""
                        description="En caso de presentar alguna inconsistencia le agradecemos enviarnos un correo a la siguiente dirección: egresados_admin@unal.edu.co"
                        backBtnText="Regresar"
                        colorBtnBack= ""
                        colorBtnContinue="#14A5DC"
                        valid={true}
                    />
                    <ErrorModal errorMsg={serviceError} handleCloseModal={handleCloseModalError} openModalError={openModalError} urlRedirect={urlRedirect}/>
                </Grid>
            }
            {logged && ready && roles.length>1 ?
                <ModalOffersAction
                    open={openModalRoles}
                    handleClose={closeModalRoles}
                    iconModal={<InfoIcon className="big-info-icon" />}
                    title="Seleccione un rol"
                    headerdescription="Usted tiene varios roles, por favor seleccione un rol para inciar sesión"
                    description=""
                    backBtnText="Regresar"
                    colorBtnBack= ""
                    continueBtnText="Ingresar"
                    linkContinue={urlRole}
                    colorBtnContinue="#f44336"
                    valid={roleSelected>0 ? true : false}
                >
                    <Grid container item xs={12} justify="center">
                        <Grid item xs={12} className="field-box-margin">
                            <Typography variant="h5" className="bold-serif">Seleccione el rol</Typography>
                            <TextField
                                fullWidth
                                id="outlined-select-estateValue"
                                select
                                name="roleSelected"
                                label={roleSelected === 0 ? "Seleccione": ""}
                                value={roleSelected === 0 ? "" : roleSelected}
                                onChange={changeRoleSelected}
                                InputLabelProps={{shrink: false}}
                                margin="none"
                                variant="outlined"
                                disabled={roleListUser.length>0 ? false : true}
                            >
                                {
                                    roleListUser && roleListUser.length>0 ?
                                    roleListUser.map((option: IRole) => (
                                        <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                        </MenuItem>
                                    ))
                                :
                                    <MenuItem value={roleSelected}></MenuItem>
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </ModalOffersAction>
            :
                <>
                {
                    logged && ready && containsAny([1],roles) &&
                    <Redirect to={redirectionPath || "/egresado/dashboard"}/>
                }
                {
                    logged && ready && containsAny([2,3],roles) && tempKey === false &&
                    <Redirect to="/empresa/dashboard"/>
                }
                {
                    logged && ready && containsAny([2,3],roles) && tempKey === true &&
                    <Redirect to="/empresa/cambio-contrasena"/>
                }
                </>
            }
        </>
    )
}

export default SignIn
