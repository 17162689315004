import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { Redirect, useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import './SettingsForm.scss';
import { IGetSurveys } from '../../../interfaces/IGetSurveys';
import SettingOptionBar from '../SettingOptionBar';
import SectionForm from '../SectionForm';
import { getSurveyHeader } from '../../../services/SurveyServices';
import HeaderForm from '../HeaderForm';
import SurveyPreview from '../SurveyPreview';

import LoadingLockPage from '../../ShareComponents/LoadingLockPage';
import ErrorModal from '../../ShareComponents/ErrorModal';
import SuccessModal from '../../ShareComponents/SuccessModal';

interface SurveyFormProps {
  action?: string;
  surveySelectedId?: number;
}

const SettingsForm: React.FC<SurveyFormProps> = ({ surveySelectedId }) => {
  const { promiseInProgress } = usePromiseTracker();
  const [errorMessage, setErrorMessage] = useState('');
  const [openModalError, setOpenModalError] = useState(false);
  const [surveyInfo, setSurveyInfo] = useState<IGetSurveys>(new IGetSurveys());
  const [selectedSection, setSelectedSection] = useState<string>('header');
  const [successMessage, setSuccessMessage] = useState('');
  const [openModalSuccess, setOpenModalSuccess] = useState(false);

  const [aux, setAux] = useState<boolean>(false);

  let params: any = useParams();

  const loadHeaderInfo = async () => {
    if (surveySelectedId) {
      const result: Response = await trackPromise(getSurveyHeader(surveySelectedId)) as Response;
      if (result.status === 200) {
        const surveyInfoLocal: IGetSurveys = new IGetSurveys((await result.json()).survey);
        setSurveyInfo(surveyInfoLocal);
      } else {
        setErrorMessage(
          'Hubo un error al consultar los datos de la encuesta, intente de nuevo mas tarde'
        );
        setOpenModalError(true);
      }
    }
  };

  const handleCloseModalError = () => {
    setOpenModalError(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelectedSection(params.selectedSection);

    loadHeaderInfo();
  }, [selectedSection]);

  return (
    <>
      {!aux ? (
        <>
          <Grid className="setting-container">
            <Typography variant="h2" className="tab-description">
              Configuración de la encuesta
            </Typography>
          </Grid>

          <SettingOptionBar loadInfo={loadHeaderInfo} selected={params.selectedSection} surveySelectedId={surveySelectedId} />
          {params.selectedSection === 'header' && surveyInfo.id && (
            <Grid className="setting-container">
              <HeaderForm surveyInfo={surveyInfo} setErrorMessage={setErrorMessage} setOpenModalError={setOpenModalError} setOpenModalSuccess={setOpenModalSuccess} setSuccessMessage={setSuccessMessage}></HeaderForm>
            </Grid>
          )}

          {params.selectedSection === 'questions' && surveyInfo.id && (
            <Grid className="setting-container">
              <SectionForm surveyInfo={surveyInfo}></SectionForm>
            </Grid>
          )}
          <ErrorModal
            errorMsg={errorMessage}
            handleCloseModal={handleCloseModalError}
            openModalError={openModalError}
          />

          <SuccessModal
            successMsg={successMessage}
            handleCloseModal={()=>{setOpenModalSuccess(false)}}
            openModalSuccess={openModalSuccess}
          />

          {params.selectedSection === 'preview' && surveyInfo.id && (
            <Grid className="setting-container">
              <SurveyPreview surveyInfo={surveyInfo}></SurveyPreview>
            </Grid>
          )}
          {/* <LoadingLockPage load={initLoad}/> */}
          <LoadingLockPage load={promiseInProgress} />
        </>
      ) : (
        <Redirect to="/admin/dashboard/encuestas-pro" />
      )}
    </>
  );
};

export default SettingsForm;
