import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

interface SurveyStateBarProps {
  selected: string;
}

const SurveyStateBar: React.FC<SurveyStateBarProps> = ({ selected }) => {

  const buttons = [
    { id: 1, name: 'Banco de preguntas', state: 'banco-preguntas', url: "/admin/dashboard/banco-preguntas" },
    { id: 2, name: 'Encuestas', state: 'encuestas', url: "/admin/dashboard/encuestas-pro" }]


  return (
    <Grid xs={12} className="state-option-section">

      <Grid item xs={12} className="state-bar-container">
        <Grid container item xs={12} className="container-buttons">
          {buttons.map(item => (
            <Link key={item.id} to={item.url} className="link-state-bar">
              <div className={selected === item.state ? 'button-definition-active' : 'button-definition'}>
                <Typography variant="h5" className="regular-serif">
                  {item.name}
                </Typography>
              </div>
            </Link>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SurveyStateBar;
