import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import BusinessIcon from '@material-ui/icons/Business';
import AssignmentIcon from '@material-ui/icons/Assignment';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import UserContext from "../../../utils/UserContext";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import NewsSurveysManagement from './NewsSurveysManagement';
import GeneralGraduateManagement from './GeneralGraduateManagement';
import GeneralCompanyManagement from './GeneralCompanyManagement';
import GeneralRolesManagement from './GeneralRolesManagement';
import { containsAny } from '../../../helpers/sharedFunctions';

import './admin.scss'
import SurveysAdminManagement from './SurveysAdminManagement';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      className="tab-panel-container"
    >
      {value === index && (
        <Box p={3}>
          <Grid container spacing={1}>
            {children}
          </Grid>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: any) => {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

interface ITabsProps {
  location: any;
}

const DashboardAdmin: React.FC<ITabsProps> = ({ location }) => {
  const routes = [
    '/admin/dashboard/egresados',
    '/admin/dashboard/mensajeria-egresados',
    '/admin/dashboard/reportes-egresados',
    '/admin/dashboard/empresas',
    '/admin/dashboard/mensajeria-empresas',
    '/admin/dashboard/reportes-empresas',
    '/admin/dashboard/reportes-ofertas',
    '/admin/dashboard/noticias',
    '/admin/dashboard/encuestas',
    '/admin/dashboard/usuarios',
    '/admin/dashboard/gestion-roles',
    '/admin/dashboard/reportes-transacciones',
    '/admin/dashboard/capacitaciones',
    '/admin/dashboard/banco-preguntas',
    '/admin/dashboard/encuestas-pro'
  ];
  const userContext = React.useContext(UserContext);
  const [value, setValue] = React.useState(0);
  const urlPath = location.pathname;
  useEffect(() => {
    if (userContext.roleSelected > 0) {
      userContext.setLogged(true);
    }
    if (urlPath === "/admin/dashboard" || urlPath === "/admin/dashboard/") {
      setValue(0)
    } else if (urlPath === routes[0]) {
      setValue(0)
    } else if (urlPath === routes[1]) {
      setValue(0)
    } else if (urlPath === routes[2]) {
      setValue(0)
    } else if (urlPath === routes[3]) {
      setValue(1)
    } else if (urlPath === routes[4]) {
      setValue(1)
    } else if (urlPath === routes[5]) {
      setValue(1)
    } else if (urlPath === routes[6]) {
      setValue(1)
    } else if (urlPath === routes[7]) {
      setValue(2)
    } else if (urlPath === routes[8]) {
      setValue(2)
    } else if (urlPath === routes[9]) {
      setValue(4)
    } else if (urlPath === routes[10]) {
      setValue(4)
    } else if (urlPath === routes[11]) {
      setValue(4)
    } else if (urlPath === routes[12]) {
      setValue(2)
    } else if (urlPath === routes[13]) {
      setValue(3)
    } else if (urlPath === routes[14]) {
      setValue(3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlPath])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  return (
    <div className="admin-tabs">
      <AppBar position="static" className="appbar-management">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
          className="tabs-management tabs-management-admin"
        >
          {
            containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
            <Tab icon={<SupervisedUserCircleIcon />} component={Link}
              to={routes[0]} label="Egresados" {...a11yProps(0)} />
          }
          {
            containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
            <Tab icon={<BusinessIcon />} component={Link}
              to={routes[3]} label="Empresas" {...a11yProps(1)} />
          }
          {
            containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
            <Tab icon={<AssignmentIcon />} component={Link} to={routes[7]} label="Noticias y Eventos" {...a11yProps(2)} />
          }
          {
            containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
            <Tab icon={<AssignmentIcon />} component={Link} to={routes[13]} label="Encuestas" {...a11yProps(2)} />
          }
          {
            containsAny([5], [userContext.roleSelected]) &&
            <Tab icon={<VerifiedUserIcon />} component={Link}
              to={routes[9]} label="Roles y permisos" {...a11yProps(4)} />
          }
        </Tabs>
      </AppBar>
      <>
        {
          containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
          <TabPanel value={value} index={0}>
            <GeneralGraduateManagement />
          </TabPanel>
        }
        {
          containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
          <TabPanel value={value} index={1}>
            <GeneralCompanyManagement />
          </TabPanel>
        }
        {
          containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
          <TabPanel value={value} index={2}>
            <NewsSurveysManagement />
          </TabPanel>
        }
        {
          containsAny([4, 5, 6, 7, 8, 9], [userContext.roleSelected]) &&
          <TabPanel value={value} index={3}>
            <SurveysAdminManagement />
          </TabPanel>
        }
        {
          containsAny([5], [userContext.roleSelected]) &&
          <TabPanel value={value} index={4}>
            <GeneralRolesManagement />
          </TabPanel>
        }
      </>
    </div>
  );
}

export default DashboardAdmin