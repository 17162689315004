import React from "react";
import { Link } from 'react-router-dom';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import "./ModalOffersAction.scss";
import UserContext from "../../../utils/UserContext";


const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: "#004B73",
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className="offer-dialog-header"
      {...other}
    >
      <Typography align="center" variant="h2" className="bold-serif" style={{ marginRight: '16px', color: 'black' }}>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ModalOffersActionProps {
  open: any;
  handleClose: any;
  handleContinue?: any;
  iconModal: any;
  title: string;
  headerdescription: string;
  description: string;
  backBtnText?: string;
  colorBtnBack?: string;
  continueBtnText?: string;
  colorBtnContinue?: string;
  linkContinue?: string;
  children?: any;
  valid: boolean;
  notLines?: boolean;
}

const ModalOffersAction: React.FC<ModalOffersActionProps> = ({
  open,
  handleClose,
  handleContinue,
  iconModal,
  title,
  headerdescription,
  description,
  backBtnText,
  colorBtnBack,
  continueBtnText,
  colorBtnContinue,
  linkContinue,
  children,
  valid,
  notLines: lines
}) => {
  const userContext = React.useContext(UserContext);
  return (
    <>
      <>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className="offer-dialog-container"
        >
          <DialogContent className="offer-icon-content">
            {iconModal}
          </DialogContent>
          <DialogContent hidden={lines}>
            <hr className="line" />
          </DialogContent>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            {title}
          </DialogTitle>
          <DialogContent className="offer-dialog-content">
            <Grid container item xs={12}>
              <span className="text-content-header">
                <Typography variant="h5" className="bold-serif">{headerdescription}</Typography>
              </span>
              <span className="text-content-dialog">
                <Typography variant="h5" className="regular-serif ">{description}</Typography>
              </span>
            </Grid>
            {children}
          </DialogContent>
          <DialogContent>
            <hr className="line" hidden={lines} />
          </DialogContent>
          <DialogActions className="offer-dialog-actions">
            <Button
              className="back-button"
              variant="contained"
              size="small"
              startIcon={<ArrowBackIosIcon />}
              onClick={handleClose}
            >
              <Typography variant="h6" className="regular-serif">
                {backBtnText}
              </Typography>
            </Button>
            {
              handleContinue &&
              <Button
                disabled={!valid}
                style={{ backgroundColor: colorBtnContinue }}
                className="continue-button"
                variant="contained"
                size="small"
                onClick={handleContinue}
              >
                <Typography variant="h6" className="regular-serif">
                  {continueBtnText}
                </Typography>
              </Button>
            }
            {
              linkContinue && linkContinue?.length > 0 &&
              <Button disabled={!valid} style={{ backgroundColor: colorBtnContinue ? colorBtnContinue : "#14A5DC" }} className="continue-button" variant="contained" size="small" component={Link} to={linkContinue}>
                <Typography variant="h6" className="regular-serif">
                  {continueBtnText}
                </Typography>
              </Button>
            }
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default ModalOffersAction;
